import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'

import VueTabs from 'vue-nav-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'

import LanguagesOfFinland from './components/LanguagesOfFinland.vue'
import LoFOverTime from './components/LoFOverTime.vue'
import LoFPerLanguage from './components/LoFPerLanguage.vue'
import LoFPerLanguageDrilldown from './components/LoFPerLanguageDrilldown.vue'

Vue.config.productionTip = false

Vue.use(VueRouter)

Vue.use(VueTabs)

const routes = [
  { path: '/', meta: { title: 'Språk i Finland 1990 - 2022' }, component: LanguagesOfFinland, 
    children: [
      { path: 'OverTime', component: LoFOverTime },
      { path: 'PerLanguage', component: LoFPerLanguage },
      { path: 'PerLanguageDrilldown', component: LoFPerLanguageDrilldown },
      { path: '', component: LoFOverTime },
    ]},
  { path: '/LanguagesOfFinland', component: LanguagesOfFinland, meta: { title: 'Språk i Finland 1990 - 2022' }, 
    children: [
      { path: 'OverTime', component: LoFOverTime },
      { path: 'PerLanguage', component: LoFPerLanguage },
      { path: 'PerLanguageDrilldown', component: LoFPerLanguageDrilldown },
      { path: '', component: LoFOverTime },
    ]},
]

const router = new VueRouter({
  mode: 'history',
  routes // short for `routes: routes`
})

router.beforeEach((to, from, next) => {
  /* It will change the title when the router is change*/
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

new Vue({
  router: router,
  render: h => h(App),
}).$mount('#app')
