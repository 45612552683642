<template>
        <div id="mainView">
            <div id="linechart">
                <div id="chartSettings">
                    <div class="settingSelect">
                        <h4>{{ strings.language }}</h4> <button v-on:click="sortLanguages('alphabetical')">ABC</button> <button v-on:click="sortLanguages('population')">123</button><br />
                       <input type="text" class="searchInput" @input="searchLanguages()" id="languageSearch" name="languageSearch" v-model="searchLanguageQuery"><br />
                       <select name="languageSelect" id="languageSelect" multiple @change="settingsChange($event)" v-model="chartConfig.languages">
                            <option v-for="language in availableLanguages" :key="language.name"  :selected="chartConfig.languages.includes(language.name)" :value="language.name">{{ translate("languages", language.name) }}</option>
                        </select>
                    </div>
                    <div class="settingSelect">
                        <h4 v-if="showMunicipalities">{{ strings.municipality }}</h4><h4 v-else-if="!showMunicipalities">{{ strings.region }}</h4> <button v-on:click="showMunicipalities = !showMunicipalities"><span v-if="showMunicipalities">{{ strings.region }}</span><span v-else-if="!showMunicipalities">{{ strings.municipality }}</span></button> <button v-on:click="sortPlaces('alphabetical')">ABC</button> <button v-on:click="sortPlaces('population')">123</button><br />
                        <input type="text" class="searchInput" @input="searchPlaces()" id="placeSearch" name="placeSearch" v-model="searchQuery"><br />
                        <select name="placeSelect" id="placeSelect" multiple @change="settingsChange($event)" v-model="chartConfig.areaNames">
                           <template v-for="place in availablePlaces">
                            <option v-if="place.isMunicipality == showMunicipalities" :key="place.name" :selected="chartConfig.areaNames.includes(place.name)" :value="place.name">{{ translate("regions", place.name) }}</option>
                           </template>
                        </select>
                    </div>
                    <form id="unitSelect" class="settingSelect">
                        <h4>{{ strings.unit }}</h4><br />
                        <input type="radio" name="choice" value="%" @change="settingsChange($event);" v-model="chartConfig.unit"> {{ translate("titles", "percent") }} (%)<br />
                        <input type="radio" name="choice" value="num" @change="settingsChange($event);" v-model="chartConfig.unit"> {{ translate("titles", "number") }}
                    </form>
                    <div id="nlg" style="visibility: hidden">
                        <h4>Om datan</h4><br />
                        <vue-tabs>
                            <v-tab v-for="language in nlgData.selectedLanguages" :key="language.language" :title="translate('languages', language.language)">
                            <ul>
                            <li>Totalt i hela landet talar {{language.total.population }} ({{language.total.percent }} %) {{  translate("languages", language.language) }}.</li>
                            <li v-if="language.largestMunicipality">{{  translate("regions", language.largestMunicipality.area) }} är den kommun med störst antal talare ({{language.largestMunicipality.population}}, {{language.largestMunicipality.percent}} %). </li>
                            <li v-if="language.largestPercentageMunicipality">{{  translate("regions", language.largestPercentageMunicipality.area) }} är den kommun med störst andel talare av totalbefolkningen ({{language.largestPercentageMunicipality.population}}, {{language.largestPercentageMunicipality.percent}} %). </li>
                            <li v-if="language.largestRegion">{{ translate("regions", language.largestRegion.area) }} är det landskap med störst antal talare ({{language.largestRegion.population}}, {{language.largestRegion.percent}} %). </li>
                            <li v-if="language.largestPercentageRegion">{{  translate("regions", language.largestPercentageRegion.area) }} är det landskap med störst andel talare av totalbefolkningen ({{language.largestPercentageRegion.population}}, {{language.largestPercentageRegion.percent}} %). </li>
                            </ul>
                            </v-tab>
                        </vue-tabs>
                    </div>
                </div>
            <svg id="sprakperkommun"></svg>
            <div id="tooltip"></div>
        </div>
        
        </div>
</template>

<script>
import * as d3 from 'd3';
import {LoFStore} from "../js/LoFStore.js";
import {LoFLoc} from "../js/LoFLoc.js";

export default {
  name: 'LoFOverTime',
  props: {
  },
  data: () => {
      return { 
        title: "",
        store: {},
        strings: {"percent": LoFLoc.translate("titles","percent"), "number": LoFLoc.translate("titles","number"),
            "language": LoFLoc.translate("titles","language"),"area": LoFLoc.translate("titles","area"),
            "municipality": LoFLoc.translate("titles","municipality"),"region": LoFLoc.translate("titles","region"),
            "unit": LoFLoc.translate("titles","unit")},
        availableLanguages: [],
        availablePlaces: [],
        searchQuery: "",
        searchLanguageQuery: "",
        showMunicipalities: true,
        selectedLanguage: LoFLoc.selectedLanguage,
        priorityLanguages: ["finska","svenska","samiska","främmande språk totalt"],
        priorityPlaces: ["Hela landet"],
        chartConfig: {
            areaNames: ["Hela landet"],
            languages: ["finska","svenska","samiska"],
            limit: 3, // number of items that can be shown simultaneously
            type: "line",
            unit: "%",
            axisStrings: {
                "num": LoFLoc.translate("titles","numberOfPersons"),
                "%": LoFLoc.translate("titles","percentOfPopulation")
            },
        },
        nlgData: { 
            selectedAreas: [], // {areaName, largestDomesticLanguage, largestDomesticLanguagePercent, domesticLanguageGrowth, domesticLanguageGrowthPercent, largestForeignLanguage, largestForeignLanguagePercent, largestGrowth, foreignLanguagesTotal}
            selectedLanguages: [] // {languageName, largestArea, largestAreaPercent, largestAreaGrowth, largestAreaGrowthPercent, ofForeignLanguagesPercent}
        },
        selectionConfig: {
            languageSelect: {mode: "alphabetical", order:"desc"},
            placeSelect: {mode: "alphabetical", order:"desc"},
        },
        window: {
            width: 0,
            height: 0,
            isMobile: false
        },
        isMounted: false
      }
  },
  mounted: function() {
      this.isMounted = true;

    if (localStorage.getItem('chartConfig_Over')) {
        try {
            this.chartConfig = JSON.parse(localStorage.getItem('chartConfig_Over'));
        } catch(e) {
            localStorage.removeItem('chartConfig_Over');
        }
    }
    
      if (LoFStore.isLoading==0) { // if no longer loading, it means it was finished before the rendered component was available, so nothing has been drawn yet

            document.getElementById("mainView").style.opacity = 1;
            this.showData(this.store, this.chartConfig);
            this.showNLG(this.store, this.chartConfig);   
            this.transitionData(1);
      }


  },
  created: function() { 
    
    // register the function to be called when data is available;
    LoFStore.registerCallback(this.onDataLoaded);

    window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
  destroyed: function() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    saveConfig: function() {
        const parsed = JSON.stringify(this.chartConfig);
        localStorage.setItem('chartConfig_Over', parsed);
    },
    translate: function(section, string) {
        return LoFLoc.translate(section ,string);
    },
    handleResize: function() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
            //console.log(this.window.width);

            
    },
    onDataLoaded: function() {
        
        this.store.data = LoFStore.store.data;
        //console.log("data loaded");
        //console.log(LoFStore.availablePlaces);
        this.availablePlaces = [...LoFStore.availablePlaces];
        this.availableLanguages = [...LoFStore.availableLanguages]; // clone to avoid modify the source of truth when filtering

        this.title = LoFLoc.translate("titles","overTimeChart") + " " + LoFStore.start_year + " - " + LoFStore.end_year;

        

        if (this.isMounted) {

            this.sortLanguages();
            this.sortPlaces();
            
            document.getElementById("mainView").style.opacity = 1;
            this.showData(this.store, this.chartConfig);
            this.showNLG(this.store, this.chartConfig);
            this.transitionData(1);
        }
    },
    transitionData: function(opacity) {
        for (let element of document.getElementsByClassName('transitioner')) {
            element.style.opacity = opacity;
        }
    },
    showData: function(store, chartConfig) {
        let newAreas = []; // map to the numerical index
        //console.log(chartConfig.areaNames);
        for (let areaName of chartConfig.areaNames) {
            newAreas.push(this.getAreaByName(store.data, areaName));
         }
        d3.select("#sprakperkommun").selectAll("*").remove();

        this.drawMunicipalityChart(newAreas, chartConfig);
    },
    getAreaByName: function(areas, area) {
        
        let key = 0;
        for (var newkey in areas) {
            if (areas[newkey].area === area) {
                key = newkey;
                break;
            }
        }
        //console.log(areas[key]);
        return areas[key];
    },
    sortLanguages: function(sortMode) {

        
        if (sortMode == 'population') {

            if (this.selectionConfig.languageSelect.mode == "alphabetical") { // if previously alphabetical, order ascending; else, reverse urrent order
                this.selectionConfig.languageSelect.order = "asc";
            } else {
                if (this.selectionConfig.languageSelect.order == "asc") this.selectionConfig.languageSelect.order = "desc";
                else this.selectionConfig.languageSelect.order = "asc";
            }

            if (this.selectionConfig.languageSelect.order == "asc") {
                this.availableLanguages = this.availableLanguages.sort((a, b) => {
                    return b.population - a.population;
                });
            } else {
                this.availableLanguages = this.availableLanguages.sort((a, b) => {
                    return a.population - b.population;
                });
            }
            // Finnish, Swedish, Sami should always be first
            let languagesPrio = [];
            for (let i = 0; i < this.availableLanguages.length; i++) {
                
                if (this.priorityLanguages.includes(this.availableLanguages[i].name)) {
                    languagesPrio.push(this.availableLanguages[i]);
                    this.availableLanguages.splice(i,1); // remove the one item
                     i -= 1; // backtrack one, since we'd otherwise be skipping over an element
                }
            }
            this.availableLanguages = languagesPrio.concat(this.availableLanguages);
        }
        else if (sortMode == 'alphabetical') {

            if (this.selectionConfig.languageSelect.mode == "population") { // if previously alphabetical, order ascending; else, reverse urrent order
                this.selectionConfig.languageSelect.order = "desc";
            } else {
                if (this.selectionConfig.languageSelect.order == "asc") this.selectionConfig.languageSelect.order = "desc";
                else this.selectionConfig.languageSelect.order = "asc";
            }

            this.availableLanguages = this.availableLanguages.sort((a, b) => {
                 return  a.locName.localeCompare(b.locName);
                });

            if (this.selectionConfig.languageSelect.order == "asc") { 
                //console.log("reversing");
                this.availableLanguages.reverse();
            }
             // Finnish, Swedish, Sami should always be first
            let languagesPrio = [];
            //console.log(this.availableLanguages.length);
            for (let i = 0; i < this.availableLanguages.length; i++) { // one element is lost here, why?
                //console.log(i + " " + this.availableLanguages[i].name); // främmande språk not here???
                if (this.priorityLanguages.includes(this.availableLanguages[i].name)) {
                    languagesPrio.push(this.availableLanguages[i]);
                    this.availableLanguages.splice(i,1); // remove the one item
                    i -= 1; // backtrack one, since we'd otherwise be skipping over an element
                }
            }
            //console.log(languagesPrio);
            this.availableLanguages = languagesPrio.concat(this.availableLanguages);
        }

        this.selectionConfig.languageSelect.mode = sortMode;
        //console.log(this.selectionConfig.languageSelect);
        document.getElementById("languageSelect").scrollTo(0,0);
    },
    sortPlaces: function(sortMode) {
        //console.log(this.selectionConfig.placeSelect.mode  + " "+ this.selectionConfig.placeSelect.order);
        if (sortMode == 'population') {
            

          if (this.selectionConfig.placeSelect.mode == "alphabetical") { // if previously alphabetical, order ascending; else, reverse urrent order
                this.selectionConfig.placeSelect.order = "desc";
            } else {
                if (this.selectionConfig.placeSelect.order == "asc") this.selectionConfig.placeSelect.order = "desc";
                else this.selectionConfig.placeSelect.order = "asc";
            }

            if (this.selectionConfig.placeSelect.order == "desc") {
                this.availablePlaces = this.availablePlaces.sort((a, b) => {
                    return b.population - a.population;
                });
            } else {
                this.availablePlaces = this.availablePlaces.sort((a, b) => {
                    return a.population - b.population;
                });
            }

            let placesPrio = [];
            for (let i = 0; i < this.availablePlaces.length; i++) {
                if (this.priorityPlaces.includes(this.availablePlaces[i].name)) {
                    placesPrio.push(this.availablePlaces[i]);
                    this.availablePlaces.splice(i,1); // remove the one item
                     i -= 1; // backtrack one, since we'd otherwise be skipping over an element
                }
            }
            this.availablePlaces = placesPrio.concat(this.availablePlaces);
        }
        else if (sortMode == 'alphabetical') {

            if (this.selectionConfig.placeSelect.mode == "population") { // if previously alphabetical, order ascending; else, reverse urrent order
                this.selectionConfig.placeSelect.order = "desc";
            } else {
                if (this.selectionConfig.placeSelect.order == "asc") this.selectionConfig.placeSelect.order = "desc";
                else this.selectionConfig.placeSelect.order = "asc";
            }

            
                this.availablePlaces = this.availablePlaces.sort((a, b) => {
                    return new Intl.Collator("sv").compare(a.locName,b.locName);
                // return  a.name.localeCompare(b.name);
                });
            
            if (this.selectionConfig.placeSelect.order == "asc") { 
                //console.log("reversing");
                this.availablePlaces.reverse();
            }

            let placesPrio = [];
            for (let i = 0; i < this.availablePlaces.length; i++) {
                if (this.priorityPlaces.includes(this.availablePlaces[i].name)) {
                    placesPrio.push(this.availablePlaces[i]);
                    this.availablePlaces.splice(i,1); // remove the one item
                     i -= 1; // backtrack one, since we'd otherwise be skipping over an element
                }
            }
            this.availablePlaces = placesPrio.concat(this.availablePlaces);
        }
        this.selectionConfig.placeSelect.mode = sortMode;
        document.getElementById("placeSelect").scrollTo(0,0);
    },
    searchPlaces: function() {
        let availableFilterPlaces = [];
        //this.availablePlaces = [...LoFStore.availablePlaces];
        if (this.searchQuery == '') { // reset
            this.availablePlaces = [...LoFStore.availablePlaces];
            return;
        }
        for (var place of LoFStore.availablePlaces) {
            if (place.locName.toLowerCase().indexOf(this.searchQuery.toLowerCase())==0) {
                availableFilterPlaces.push(place);
            }
        }
        this.availablePlaces = availableFilterPlaces;
    },
    searchLanguages: function() {
        let availableFilterLanguages = [];
        if (this.searchLanguageQuery == '') { // reset
            this.availableLanguages = [...LoFStore.availableLanguages];
            return;
        }
        for (var language of LoFStore.availableLanguages) {
            if (language.locName.toLowerCase().indexOf(this.searchLanguageQuery.toLowerCase())==0) {
                availableFilterLanguages.push(language);
            }
        }
        this.availableLanguages = availableFilterLanguages;
    },
    // when a change has been made in the selections above, trigger redraw of the chart
    settingsChange: function (event) {
        if (event.srcElement.id=="placeSelect" && this.chartConfig.areaNames.length > 1) {
            this.chartConfig.languages = [this.chartConfig.languages[0]];

        }
        if (event.srcElement.id=="placeSelect" && this.chartConfig.areaNames.length > this.chartConfig.limit) {
            this.chartConfig.areaNames = this.chartConfig.areaNames.slice(1); // drop one
        
        } else if (event.srcElement.id=="languageSelect" &&  this.chartConfig.languages.length > 1) { // deselect to declutter
            this.chartConfig.areaNames = [this.chartConfig.areaNames[0]];
        }

        // if selection is 0 - do nothing; do not update until there is a selection
        if (event.srcElement.id=="placeSelect" && this.chartConfig.areaNames.length == 0) {
            return;
        
        }
        if (event.srcElement.id=="languageSelect" && this.chartConfig.languages.length == 0) {
            return;
        
        }

        this.updateChart(this.store, this.chartConfig); // refresh data    
        this.showNLG(this.store, this.chartConfig);    
        this.saveConfig();
    }, 
    showNLG: function(store, chartConfig) {
       //         nlgData: { 
       //     selectedAreas: [], // {areaName, largestDomesticLanguage, largstDomesticLanguagePercent, domesticLanguageGrowth, domesticLanguageGrowthPercent, largestForeignLanguage, largestForeignLanguagePercent, largestGrowth, foreignLanguagesTotal}
       //     selectedLanguages: [] // {languageName, largestArea, largestAreaPercent, largestAreaGrowth, largestAreaGrowthPercent, ofForeignLanguagesPercent}
       // },
        this.nlgData.selectedLanguages = []; 
       // 1 store the data for the selected languages
       console.log(store.data);
       for (let language of chartConfig.languages) {
        let languageStat = {};
        // 1a find the largest area and percent
        //let largestArea = "";
        let largestMunicipalityPopulation = 0;
       // let largestAreaPopulationPercentage = 0;
       // let largestAreaPopulationGrowth = 0;
       // let largestAreaPopulationGrowthPercentage = 0;
        let largestMunicipalityIndex = "";
        let latestYearIndex = store.data[0].years.length-1;

        let largestPercentageMunicipalityIndex = "";
        let largestPercentageMunicipalityPopulation = 0;
        let largestPercentageMunicipalityPercent = 0;

        let largestRegionIndex = "";
        let largestRegionPopulation = 0;

        let largestPercentageRegionIndex = "";
        let largestPercentageRegionPopulation = 0;
        let largestPercentageRegionPercent = 0;
        //console.log(latestYearIndex);
        for (let areaDataIndex in store.data) {
            //console.log(store.data[areaDataIndex].years[latestYearIndex]);
            if (store.data[areaDataIndex].area=="Hela landet") {
                languageStat.total = {"population":store.data[areaDataIndex].years[latestYearIndex][language], "percent": (100* store.data[areaDataIndex].years[latestYearIndex][language] / store.data[areaDataIndex].years[latestYearIndex]["totalt"]).toFixed(2)};
                continue;
            }
            else if (store.data[areaDataIndex].isMunicipality) {
                if (store.data[areaDataIndex].years[latestYearIndex][language]>largestMunicipalityPopulation) {
                    largestMunicipalityPopulation = store.data[areaDataIndex].years[latestYearIndex][language];
                    largestMunicipalityIndex = areaDataIndex;
                }
                let percent = 100* store.data[areaDataIndex].years[latestYearIndex][language] / store.data[areaDataIndex].years[latestYearIndex]["totalt"];
                if (percent > largestPercentageMunicipalityPercent) {
                    largestPercentageMunicipalityIndex = areaDataIndex;
                    largestPercentageMunicipalityPopulation = store.data[areaDataIndex].years[latestYearIndex][language];
                    largestPercentageMunicipalityPercent = percent;
                }
            }
            else {
                if (store.data[areaDataIndex].years[latestYearIndex][language]>largestRegionPopulation) {
                    largestRegionPopulation = store.data[areaDataIndex].years[latestYearIndex][language];
                    largestRegionIndex = areaDataIndex;
                }
                let percent = 100* store.data[areaDataIndex].years[latestYearIndex][language] / store.data[areaDataIndex].years[latestYearIndex]["totalt"];
                if (percent > largestPercentageRegionPercent) {
                    largestPercentageRegionIndex = areaDataIndex;
                    largestPercentageRegionPopulation = store.data[areaDataIndex].years[latestYearIndex][language];
                    largestPercentageRegionPercent = percent;
                }
            }



        }
        languageStat.language=language;

        //let populationDiff = largestMunicipalityPopulation - store.data[largestMunicipalityIndex].years[latestYearIndex-1][language];
        if (largestMunicipalityIndex!="")
            languageStat.largestMunicipality = {"area": store.data[largestMunicipalityIndex].area, "population": largestMunicipalityPopulation, "percent": (100*(largestMunicipalityPopulation / store.data[largestMunicipalityIndex].years[latestYearIndex]["totalt"])).toFixed(2)};

        if (largestRegionIndex!="")
            languageStat.largestRegion = {"area": store.data[largestRegionIndex].area, "population": largestRegionPopulation, "percent": (100*(largestRegionPopulation / store.data[largestRegionIndex].years[latestYearIndex]["totalt"])).toFixed(2)};

        if (largestPercentageMunicipalityIndex!="")
            languageStat.largestPercentageMunicipality = {"area": store.data[largestPercentageMunicipalityIndex].area, "population": largestPercentageMunicipalityPopulation,"percent": largestPercentageMunicipalityPercent.toFixed(2)} ;
        
        if (largestPercentageRegionIndex!="")
            languageStat.largestPercentageRegion = {"area": store.data[largestPercentageRegionIndex].area, "population": largestPercentageRegionPopulation, "percent": largestPercentageRegionPercent.toFixed(2)};
  
        this.nlgData.selectedLanguages.push(languageStat);
       }
        console.log(this.nlgData.selectedLanguages);
       // 2 store the data for the selected regions
      // for (let area of chartConfig.areas) {

      // }
    },
    updateChart: function (store, chartConfig) {
        this.transitionData(0);

        //console.log(chartConfig.languages);

        setTimeout(() => {
            this.showData(store, chartConfig);
            setTimeout(() => {this.transitionData(1)}, 250);
        }, 250);

    },
    drawMunicipalityChart: function(languages, dataConfig) {
        let config = this.getMunicipalityChartConfig();
        //console.log("config: " + config);

        let scales = this.getMunicipalityChartScales(languages, config, dataConfig);
        this.drawAxesMunicipalityChart(languages, scales, config);
        this.drawFiguresMunicipalityChart(languages, scales, config, dataConfig);
    
    },
    getMunicipalityChartConfig: function() {
        
        let container = d3.select("#sprakperkommun");

        let width = this.window.width - 25*2; //this.$refs.app.clientWidth;

        if (width < 1024) this.window.isMobile = true;

        let height = (!this.window.isMobile||width>600)?600:width; // make it quadratic

        //console.log("width: " + width);

        let margin = {
            top: 50,
            bottom: 50,
            left: (!this.window.isMobile)?75:25,
            right: (!this.window.isMobile)?250:25
        };
        //The body is the are that will be occupied by the bars.
        let bodyHeight = height - margin.top - margin.bottom;
        let bodyWidth = width - margin.left - margin.right;

        container
            .attr("width", width)
            .attr("height",height);


        return { width, height, margin, bodyHeight, bodyWidth, container };
    },

    getMunicipalityChartScales: function(areas, config, dataConfig) {
        let { bodyWidth, bodyHeight } = config;

        let maximumCount = d3.max(areas, d => {
            return d3.max(Object.keys(d.years), y => {
                if (dataConfig.unit == "%" ) { 
                        
                        let max = 0;
                        // if max value is less than 1%, 
                        for (let language of dataConfig.languages) { // this is a bit silly, but doing a third max by iterating over language...
                            if (((d.years[y][language]/d.years[y].totalt)*100) > max) max = (d.years[y][language]/d.years[y].totalt)*100;
                            //console.log("max: " + max);
                        }
                        return max;
                    }
                    else if (dataConfig.unit =="num") { // absolute numbers
                        let max = 0;
                        for (let language of dataConfig.languages) {
                            if (d.years[y][language] > max) max = d.years[y][language];
                        }
                        return max;
                }
            })
        });

        // if everyting observed is below 0.75%, then do maxes in units of 0.10
        if (maximumCount < 0.75 && dataConfig.unit == "%" ) {
            let newMax = 0;
            while (newMax <= maximumCount) {
                newMax += 0.1;
            }
            maximumCount = newMax;

        }
        else {    
            maximumCount = Math.ceil(maximumCount); // round up
        }
        //console.log("max: " + maximumCount + " " + Object.keys(areas[0].Years).length + " " + bodyWidth);

        let xScale = d3.scaleBand()
            .range([0,bodyWidth])
            .domain(Object.keys(areas[0].years)) // assumption: all municipalities have data for all years!
            .padding(0.25); // internal padding, was 1

        let yScale = d3.scaleLinear()
            .range([bodyHeight, 0]) // reverse scale
            .domain([0, maximumCount]); //Maximum percentage observed
        
        return { xScale, yScale, maximumCount };
    },

    drawFiguresMunicipalityChart: function(areas, scales, config, dataConfig) {
        let {margin, container, bodyWidth } = config; // this is equivalent to 'let margin = config.margin; let container = config.container'
        let {xScale, yScale, maximumCount} = scales;
        let body = container.append("g")
            .style("transform", 
            `translate(${margin.left}px,${margin.top}px)`
            );

        let keys = Object.keys(areas);  
        let extendedKeys = [];

        let langKeys = [];
        let labelStrings = [];
        for (let language of dataConfig.languages) {
            langKeys.push(language);
        }

        for (let language of dataConfig.languages) {
            for (let key in keys) {
                extendedKeys.push(key);
                labelStrings.push(`${LoFLoc.translate("regions",areas[key].area)}, ${LoFLoc.translate("languages",language)}`);
            }
        }
    //console.log(keys);

        let firstYear = d3.min(Object.keys(areas[0].years), 
            d => (areas[0].years[d].year));

            // colors
        config.color = d3.scaleOrdinal()
            .domain(extendedKeys)
            .range(d3.schemeSet1);
        //console.log(color(0));
    
        if (dataConfig.type === "bar") {
            let area_bars = [];
            let index = 0;
            for (let selectedLanguage of dataConfig.languages) {
                for (let area of areas) {
                    let bars = body.selectAll(".bar").data(area.years);
                   //Adding a rect tag for each year
                   bars.enter().append("rect")
                      .attr("width", xScale.bandwidth())
                      .attr("x", (d) => xScale(d.year-firstYear) + (index*4) )
                      .attr("height", (d) =>  {
                
                            if (dataConfig.unit =="%") return yScale(maximumCount - (d[selectedLanguage]/d.totalt)*100);
                            else if (dataConfig.unit =="num") return yScale(maximumCount - d[selectedLanguage]);
            
                       } )
                    .attr("y", (d) => {
                        if (dataConfig.unit =="%") return yScale(0) - yScale(maximumCount - (d[selectedLanguage]/d.totalt)*100);
                        else if (dataConfig.unit =="num") return yScale(0) - yScale(maximumCount - d[selectedLanguage]);
                    } )
                    .attr("fill", config.color(index));
                    index++;
                    area_bars.push(bars); // is this strictly necessary?
                }      
            }
        } else if (dataConfig.type === "line") {
            let index = 0;
            for (let selectedLanguage of dataConfig.languages) {
                for (let area of areas) {
                    //let line = body.select(".line").datum(area.years);
                    let valueline = d3.line()
                        .x((d) => xScale(d.year-firstYear)+ (!this.window.isMobile?20:0)) // offset so that the line switch at the tick
                        .y((d) => {
                            if (dataConfig.unit =="%") return yScale(0) - yScale(maximumCount - (d[selectedLanguage]/d.totalt)*100);
                            else if (dataConfig.unit =="num") return yScale(0) - yScale(maximumCount - d[selectedLanguage]);
                        });

                    body.append("path")
                        .datum(area.years)
                        .attr("d", valueline)
                        .attr("class","chartLine transitioner")
                        .attr("style", "stroke: " + config.color(index))
                        
                    index++;
            
                }
            }

            body.append("line")
                .attr("class", "tooltipLine")
                .attr("x1",0)
                .attr("x2",0)
                .attr("y1",0)
                .attr("y2",config.height - margin.top - margin.bottom)
                .attr("style","opacity: 0");

        }
        // legends
        if (!this.window.isMobile) {
            let size = 25;
            body.selectAll(".legend_colors")
                .data(extendedKeys)
                .enter()
                .append("rect")
                .attr("x", bodyWidth+10)
                .attr("y", function(d,i){ return 0 + i*(size+5)}) // 100 is where the first dot appears. 25 is the distance between dots
                .attr("width", size)
                .attr("height", size)
                .attr("class","transitioner")
                .style("fill", function(d,i){ return config.color(i)}) // todo fix for langauges
                .style("stroke", "black");

            // Add one dot in the legend for each name.
            body.selectAll(".legend_labels")
                .data(extendedKeys)
                .enter()
                .append("text")
                .attr("x", (bodyWidth+10) + size*1.2)
                .attr("y", function(d,i){ return 0 + i*(size+5) + (size/2)}) // 100 is where the first dot appears. 25 is the distance between dots
                .attr("class","transitioner")
                .style("fill", "black")
                .text(function(d, i){ return labelStrings[i]})// todo fix for languages
                .attr("text-anchor", "left")
                .attr("font-size", 12)
                .style("alignment-baseline", "middle");
        }

        // tooltips
        //console.log("attaching tool tip rect, with: " + config.bodyWidth);
        var tooltipRect = container.append("rect") // rectangle to capture mouse event
            .style("transform", `translate(${margin.left}px,${margin.top}px)`)
            .style("fill", "none")
            .style("pointer-events", "all")
            .style("width",config.bodyWidth)
            .style("height",config.bodyHeight)
            .attr("width",config.bodyWidth) // note, the style attributes weren't enough for firefox
            .attr("height",config.bodyHeight);

        tooltipRect.on("mouseenter", (event) => {
                    this.showTooltip([d3.pointer(event)[0], d3.pointer(event)[1]], [event.clientX, event.clientY], xScale, config, dataConfig, areas);
                })
                .on("mousemove", (event) => {
                    //console.log("a: " + d3.pointer(event)[1]);
                     this.showTooltip([d3.pointer(event)[0], d3.pointer(event)[1]], [event.clientX, event.clientY], xScale, config, dataConfig, areas);
                 })
                .on("mouseleave", () => {
                     this.hideTooltip();
        });


    },

    drawAxesMunicipalityChart: function (areas, scales, config) {
        let {xScale, yScale } = scales;
        let {container, margin, width, height} = config;

        let ticks = [];
        for (let year of areas[0].years) {
            ticks.push(""+year.year);
        }

     //   console.log(ticks);
       if (this.window.isMobile) {
            let firstYear = ticks[0];
            let lastYear = ticks[ticks.length-1];
            
            for (var i = 0; i < ticks.length; i++) { 
                ticks[i] = ""; 
            }
            ticks[0] = firstYear;
            ticks[ticks.length-1] = lastYear;
        }
        // console.log(ticks[2]);



        let axisX = d3.axisBottom(xScale)
            .ticks(ticks)
            .tickFormat((d) => ticks[d]);


            
        container.append("g")
            .style("transform", 
            `translate(${margin.left}px,${height - margin.bottom}px)`
            )
            .call(axisX);

        let axisY = d3.axisLeft(yScale).ticks(16); //TODO: Create an axis on the left for the Y scale

        // Append a g tag to the container, translate it based on the margins and call the axisY axis to draw the left axis.
        if (!this.window.isMobile) { // don't add labels if screen size to small
        container.append("g")
            .style("transform", 
            `translate(${margin.left}px,${margin.top}px)`
            )
            .attr("class","transitioner")
            .call(axisY);
        }

        let gridY = d3.axisLeft(yScale).ticks(16);

        container.append("g")			
            .attr("class", "grid transitioner")
            .style("transform", `translate(${margin.left}px,${margin.top}px)`)
            .call(gridY
                .tickSize(-(width-margin.right-margin.left))
                .tickFormat("")
            );


        // title
        container.append("text")
            .attr("x",(width/2))            
            .attr("y",25)
            .style("text-anchor", "middle")
            .attr("class","inChartTitle")
            .text(this.title);

        // axes labels
        // text label for the x axis
        // only attach if there's sufficient screen real estate
        if (!this.window.isMobile) {
            container.append("text")
              .attr("x",(width/2))            
              .attr("y",(height)-10)
               .attr("font-size",14)
               .style("text-anchor", "middle")
               .text(this.translate("titles","years"));

            // Add the y Axis
         // text label for the y axis
          container.append("text")
                .attr("transform", "rotate(-90)")
                .attr("y", 10)
                .attr("x",0 - ((height -margin.top)/ 2))
               .attr("font-size",14)
               .attr("dy", "1em")
               .style("text-anchor", "middle")
               .text((this.chartConfig.unit=="%")?this.translate("titles","percentOfPopulation"):this.translate("titles","numberOfPersons"));  
        }

        
    },
    hideTooltip: function() {
        d3.select("#tooltip").style("display","none");
        d3.select(".tooltipLine").style("opacity","0");
    },
    /* coords - the coordinates relative to the tooltip rectangle which covers the entire chart area
       abscoords - the absolute mouse coordinates
       areas - the data, which has already been filtered for the selected areas
    */
    showTooltip: function(coords, abscoords, xScale, config, dataConfig, areas) {
        //console.log(coords[1] + " " + abscoords[1]);
        let closestYearIndex = this.calculateClosestYear(coords, xScale, config);
        if (closestYearIndex < 0 ||closestYearIndex>=areas[0].years.length) {
            if (!this.window.isMobile) { // if mobile, keep the tooltip visible at all times and simply don't move it
                this.hideTooltip();
                return; // out of bounds,
            }
            
        }
        let closestYear = areas[0].years[closestYearIndex].year; // all the elements should have the same years, so just grab the first one
       // let value = data[closestYearIndex][language];
        //let percentage = 100* data[closestYearIndex][language]/data[closestYearIndex].totalt;

        let toolTipString = "<strong>"+closestYear+"</strong><br />";

        let i = 0;
        for (let selectedLanguage of dataConfig.languages) {
                for (let area of areas) {
                    let value = area.years[closestYearIndex][selectedLanguage];
                    let percentage = 100* area.years[closestYearIndex][selectedLanguage]/area.years[closestYearIndex].totalt;
                    if (this.window.isMobile) {
                        toolTipString += "<div style=\"float: left; border: 1px solid black; margin-right: 5px; background-color: " + config.color(i) + "; width: 20px\">&nbsp;</div><div style=\"float: left\">";
                    }
                    toolTipString += "" + this.translate("regions", area.area) + ", " + this.translate("languages", selectedLanguage) + ": <strong>" + value +  "</strong> (<strong>" + percentage.toFixed(2)+" %</strong>)";

                    if (this.window.isMobile) { // adding divs to get the legend color box to appear properly
                        toolTipString += "</div><div style=\"clear: both\"></div>";
                    } else toolTipString +="<br />\r\n";
                    i++;
                }
                
        }

        d3.select(".tooltipLine")
            .attr("x1",coords[0])
            .attr("x2",coords[0])
            .attr("style","opacity: 1");

        // adjust if at edge of screen
        let tooltipXAdjustment = 0;

       if (coords[0]+config.margin.left+200 >= this.window.width ) {
           tooltipXAdjustment = coords[0]+config.margin.left+200 - this.window.width;
       }
        if (!this.window.isMobile) {
        d3.select("#tooltip").html(toolTipString)
            .style("display","block")
            .style("left", (coords[0]+config.margin.left-tooltipXAdjustment)+"px")
            .style("top", (abscoords[1]+30)+"px"); // the tooltip div uses position absolute
        } else {
           d3.select("#tooltip").html(toolTipString)
            .style("display","block")
            .style("right", 25+"px")
            .style("top", (abscoords[1]+30)+"px"); // the tooltip div uses position absolute 
        }

    },
    calculateClosestYear: function(coords, xScale) {
        // var mouseYear = xScale.invert(coords[0]); // doesn't work - xScale is a Scaleband
    
        var eachBand = xScale.step();
        var index = Math.floor(((coords[0]) / eachBand) - 0.5); // 0.5 so that it switches the year at the tick and not halfway between
        return index; 
    }

  }
}

</script>
<style>
         #tooltip {
            border: 1px solid black;
            padding: 5px;
            position: fixed;
            display: none;
            font-size: 14px;
            background-color: white;
        }

        #lineSettings {
            display: flex;
        }

        #sprakperkommun {
            margin-left: 25px;
        }

        #nlg {
            margin-left: 10px;
            float: right;
        }

        button {
            background:white;
            border: 1px solid black;
            font-size: 12px;
            padding: 2px;
        }


        .grid line {
            stroke: lightgrey;
            stroke-opacity: 0.7;
            shape-rendering: crispEdges;
          }
          
          .grid path {
            stroke-width: 0;
          }

        .chartLine {
            fill: none;
            stroke: black;
            stroke-width: 12;
        }

        .tooltipLine {
            stroke-dasharray: 5;
            stroke: black;
        }
</style>
