<template>
        <div id="mainView">
            <div id="linechart">
                <div id="chartSettings">
                    <form id="unitSelect" class="settingSelect">
                        <h4>{{ strings.unit }}</h4><br />
                        <input type="radio" name="choice" value="%" @change="settingsChange($event);" v-model="chartConfig.unit"> {{ translate("titles", "percent") }} (%)<br />
                        <input type="radio" name="choice" value="num" @change="settingsChange($event);" v-model="chartConfig.unit"> {{ translate("titles", "number") }}
                    </form>
                    <form id="areaSelect" class="settingSelect">
                        <h4>{{ strings.region }} / {{ strings.municipality }}</h4><br />
                        <input type="radio" name="choice" value="municipality" @change="settingsChange($event);" v-model="showRegion"> {{ strings.municipality }}<br />
                        <input type="radio" name="choice" value="region" @change="settingsChange($event);" v-model="showRegion"> {{ strings.region }}
                    </form>
                    <form id="yearSelect" class="settingSelect">
                        <h4>{{ translate("titles", "year") }}</h4><br />
                        <input type="range" :min="startingYear" :max="endingYear" v-model="selectedYear" @change="settingsChange($event);" class="slider" id="myRange"> {{selectedYear}}
                    </form>
                    <div style="clear: both"></div>
                </div>
                <div id="charts" class="transitioner"></div>
            </div>
        
        </div>
</template>

<script>
import * as d3 from 'd3';
import {LoFStore} from "../js/LoFStore.js";
import {LoFLoc} from "../js/LoFLoc.js";

export default {
  name: 'LoFPerLanguage',
  props: {
  },
  data: () => {
      return { 
        title: "Språk per område",
        store: {},
        strings: {"percent": LoFLoc.translate("titles","percent"), "number": LoFLoc.translate("titles","number"),
            "language": LoFLoc.translate("titles","language"),"area": LoFLoc.translate("titles","area"),
            "municipality": LoFLoc.translate("titles","municipality"),"region": LoFLoc.translate("titles","region"),
            "unit": LoFLoc.translate("titles","unit")},
        availableLanguages: [],
        availablePlaces: [],
        showRegion: "municipality",
        priorityLanguages: ["finska","svenska","samiska","främmande språk totalt"],
        suppressedLanguages: [], // these will get their own drilldown
        selectedYear: 0,
        startingYear: 0,
        endingYear: 0,
        chartConfig: {
            type: "line",
            unit: "num",
            area: "municipality",
            number: 16, // number of charts - that is, number of languages
            limit: 10, // number of areas per chart
            barColor: "#113399",
            defaultWidth: 440,
            defaultHeight: 400
        },
        charts: {},
        window: {
            width: 0,
            height: 0,
            isMobile: false
        },
        isMounted: false // use this to check if the component has been rendered yet - if not, don't attempt adding elements
      }
  },
  created: function() { 
    
    // register the function to be called when data is available;
    LoFStore.registerCallback(this.onDataLoaded);

    window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    mounted: function() {
        this.isMounted = true;
        if (LoFStore.isLoading == 0) { // this means it stopped loading before mounting happened, so no rendering has taken place yet
            document.getElementById("mainView").style.opacity = 1;
            this.showData(this.store, this.chartConfig);
            this.transitionData(1);
        }

        if (localStorage.getItem('chartConfig_Per')) {
            try {
                this.chartConfig = JSON.parse(localStorage.getItem('chartConfig_Per'));
            } catch(e) {
                localStorage.removeItem('chartConfig_Per');
            }
        }
    },
  destroyed: function() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
      saveConfig: function() {
        const parsed = JSON.stringify(this.chartConfig);
        localStorage.setItem('chartConfig_Per', parsed);
    },
    handleResize: function() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
            //console.log(this.window.width);
    },
        translate: function(section, string) {
        return LoFLoc.translate(section ,string);
    },
    onDataLoaded: function() {
        
        this.store.data = LoFStore.store.data;
        console.log("data loaded");
      //  console.log(LoFStore.availableLanguages);
        this.availablePlaces = [...LoFStore.availablePlaces];
        this.availableLanguages = [...LoFStore.availableLanguages]; // clone so we don't modify the source of truth


        this.startingYear = LoFStore.start_year;
        this.endingYear = LoFStore.end_year;
        this.selectedYear = LoFStore.end_year;

        console.log(this.availableLanguages);
        this.sortLanguagesByPopulation(); // sort from biggest to smallest;

        if (this.isMounted) { // if not mounted, the component hasn't been rendered yet, so there's nothing to write on
            document.getElementById("mainView").style.opacity = 1;
            this.showData(this.store, this.chartConfig);
            this.transitionData(1);
        }
    },
    transitionData: function(opacity) {
        for (let element of document.getElementsByClassName('transitioner')) {
            element.style.opacity = opacity;
        }
    },

    getAreaByName: function(areas, area) {
        let key = 0;
        for (var newkey in areas) {
            if (areas[newkey].area === area) {
                key = newkey;
                break;
            }
        }
        return areas[key];
    },
    sortLanguagesByPopulation: function() {
        this.availableLanguages = this.availableLanguages.sort((a, b) => {
                return b.population - a.population;
            });
            // Finnish, Swedish, Sami should always be first
            let priorityLanguageData = [];
            for (let i = 0; i < this.availableLanguages.length; i++) {
                
                if (this.priorityLanguages.includes(this.availableLanguages[i].name)) {
                    priorityLanguageData.push(this.availableLanguages[i]); // this will happen on first load; after that, availableLanguages have modified
                    this.availableLanguages.splice(i,1); // remove the one item
                }

                else if (this.suppressedLanguages.includes(this.availableLanguages[i].name)) { // filter out
                    this.availableLanguages.splice(i,1); // remove the one item
                }
        }
        this.availableLanguages = priorityLanguageData.concat(this.availableLanguages); // this should probably be a shallow copy
        console.log(this.availableLanguages);
    },

    // when a change has been made in the selections above, trigger redraw of the chart
    settingsChange: function (/*event*/) {
        this.updateChart(this.store, this.chartConfig); // refresh data        
        this.saveConfig();
    }, 

    updateChart: function (store, chartConfig) {
        this.transitionData(0);
       // this.showData(store, chartConfig);
        setTimeout(() => {
            this.showData(store, chartConfig);
            setTimeout(() => {this.transitionData(1)}, 250);
        }, 250);

    },
    showData: function(store, chartConfig) {
        console.log(store.data);
        this.charts = this.makeChartData(store.data); 
  
        this.drawCharts(this.charts, chartConfig);
    },
    makeChartData: function(areas) {
        let charts = [];
        // first, get the X largest languages by total population
  
        let languages = this.availableLanguages.slice(0,this.chartConfig.number);
        
        // then, for each language, find the Y municipalities with the highest proportion, from the selected year
        for (let language of languages) {
            let languageChart = {name: language.name, locName: LoFLoc.translate("languages",language.name), areas: []};
            languageChart.areas = this.getLargestPerLanguage(language.name, areas).slice(0,this.chartConfig.limit);
            
            charts.push(languageChart);
        }

        return charts;
    },
    getLargestPerLanguage: function (languageName, areas) {
        let filteredAreas = [];
        
        for (let area of areas) {
            if (area.area == "Hela landet") continue; // skip it for the moment
            //console.log(area);

            // filter: sort only municipalities or regions, depending on configuration, but not both at the same time
            if (this.showRegion=="municipality" && !area.isMunicipality) {
                continue;
            }
            if (this.showRegion=="region" && area.isMunicipality) {
                continue;
            }
            let filteredArea = {};
            filteredArea.name = LoFLoc.translate("regions",area.area);
            
            for (let y in area.years) {
                if (area.years[y].year == this.selectedYear) {
                    filteredArea.totalt = area.years[y].totalt;
                    filteredArea.languageTotal = area.years[y][languageName];
                    filteredArea.languagePercentage = (area.years[y][languageName] / area.years[y].totalt);
                    break;
                }
            }
            filteredAreas.push(filteredArea);
        }

        filteredAreas.sort((c1,c2) => {
            if (this.chartConfig.unit == "num") {
                return c2.languageTotal - c1.languageTotal;
            } else if (this.chartConfig.unit == "%") {
                return c2.languagePercentage - c1.languagePercentage;
            }
        });
        return filteredAreas;

    },
    drawCharts: function(charts, dataConfig) {
        let config = this.getChartConfig();


       let chartsContainer = document.getElementById("charts");
       
        // clear the charts first
       while (chartsContainer.firstChild) {
           chartsContainer.removeChild(chartsContainer.lastChild);
        }
        

      for (let chart of charts) {
            // dynamic binding with vue and d3 a bit tricky it seems, so brute forcing in the svg elements
            let svg = document.createElementNS("http://www.w3.org/2000/svg","svg");
            svg.setAttribute("class","område transitioner");
            svg.setAttribute("id","chart_"+chart.name.replaceAll(" ","_")); 
            chartsContainer.appendChild(svg);

            let container = d3.select("#chart_"+chart.name.replaceAll(" ","_"));
            container.selectAll("*").remove(); // clean it first

            let scales = this.getChartScales(chart, config);

            container
            .attr("width", config.width)
            .attr("height", config.height);

            this.drawAxesChart(container, chart, scales, config);
            this.drawFiguresChart(container, chart, scales, config, dataConfig);
        }
        
    
    },
    getChartConfig: function() { // this config applies for all of the chart
    
        if (this.window.width < 1024) this.window.isMobile = true;

        let width = this.chartConfig.defaultWidth; //this.$refs.app.clientWidth; // todo modify these for mobile
        let height = this.chartConfig.defaultHeight;

        if (this.window.isMobile) {
            width = 400; // original
           if (this.window.width < 500) width = this.window.width - 25*2;
            height = width; // make it quadratic
        }

        let margin = {
            top: 25,
            bottom: 25,
            left: 105,
            right: 20
        };
        //The body is the are that will be occupied by the bars.
        let bodyHeight = height - margin.top - margin.bottom;
        let bodyWidth = width - margin.left - margin.right;

        return { width, height, margin, bodyHeight, bodyWidth };
    },

    getChartScales: function(data, config) {
        let { bodyWidth, bodyHeight  } = config;

        let maximumCount = 0;
        
        if (this.chartConfig.unit == "num") {
            maximumCount = data.areas[0].languageTotal; // since the data is already sorted by size, this should be the largest one
           // maximumCount = maximumCount; // round up
        }
        else if (this.chartConfig.unit == "%") {
            maximumCount = data.areas[0].languagePercentage;
            maximumCount *= 1.01; // add a small cushion
        }

        console.log(maximumCount);

        let yScale = d3.scaleBand()
            .domain(d3.range(data.areas.length))
            .rangeRound([0, bodyHeight])
            .padding(0.5);
            

        let xScale = d3.scaleLinear()
            .rangeRound([0, bodyWidth]) 
            .domain([0, maximumCount]); //Maximum percentage observed
    

        return { xScale, yScale, maximumCount };
    },

    drawFiguresChart: function(container, data, scales, config, dataConfig) {
        let { margin, width } = config; // this is equivalent to 'let margin = config.margin; let container = config.container'

        let {xScale, yScale } = scales;
        let body = container.append("g")
            .style("transform", 
            `translate(${margin.left}px,${margin.top}px)`
            );

        body.append("text")
                .attr("x",(width/2)-(margin.right + margin.left)/2)            
                .attr("y", 0)
                .attr("class","inChartTitleSmaller")
                .style("text-anchor", "middle")
                .text(data.locName + " " + this.selectedYear);   
        
        let bars = body.selectAll(".bar").data(data.areas);
        //Adding a rect tag for each year
        bars.enter().append("rect")
            .attr("x", 0)
            .attr("y", (d, i) => { 
                
                return yScale(i);
            })
            .attr("width", (d) =>  {
                            if (dataConfig.unit =="%") return xScale(d.languagePercentage);
                            else if (dataConfig.unit =="num") return xScale( d.languageTotal);
                       } )
            .attr("height", yScale.bandwidth())
            .attr("fill",this.chartConfig.barColor)
            .attr("class","chartBar");
    },

   drawAxesChart: function (container, data, scales, config) {
       
        let { yScale, xScale } = scales;
        let {margin, height,  } = config;

        let labels = data.areas.map((d) =>d.name)
        let axisY = d3.axisLeft(yScale)
            .ticks(labels.length)
            .tickFormat((d) => labels[d]);

        let axisX; 
        let gridX; 

        if (this.window.isMobile) {
            axisX = d3.axisBottom(xScale).ticks(3)
             .tickFormat( (d) =>  ( (this.chartConfig.unit=="num")? d :((d*100).toFixed(2)+"%") ) );
            gridX = d3.axisBottom(xScale).ticks(10);
        } else {
            axisX= d3.axisBottom(xScale).ticks(6)
                .tickFormat( (d) =>  ( (this.chartConfig.unit=="num")? d :((d*100).toFixed(2)+"%") ) );
            gridX = d3.axisBottom(xScale).ticks(6);
        }

        // grid - attach first so the axes overlay
        container.append("g")			
            .attr("class", "grid transitioner")
            .style("transform", `translate(${margin.left}px,${height - margin.bottom}px)`)
            .call(gridX
                .tickSize(-(height-margin.top-margin.bottom))
                .tickFormat("")
            );
            
        container.append("g")
            .attr("class", "transitioner")
            .style("transform", 
            `translate(${margin.left}px,${margin.top}px)`
            )
            .call(axisY);

        container.append("g")
        .attr("class", "transitioner")
        .style("transform", 
            `translate(${margin.left}px,${height - margin.bottom}px)`
            )
            .call(axisX);

        

    },
  }
}

</script>
<style>

        .område {
            margin-left: 25px;
            margin-bottom: 25px;
            float: left;
        }

        .chartBar {
            fill: #003366;
            stroke: black;
            stroke-width: 0;
        }
</style>
