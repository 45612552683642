<template>
    <div id="chartContent">
        <h1>{{ title }}</h1>
        <div id="subchartMenu">
            <div id="routers">
                <router-link class="submenuLink" to="/LanguagesOfFinland/OverTime">{{ strings.overTime }}</router-link>
                <router-link class="submenuLink" to="/LanguagesOfFinland/PerLanguage">{{ strings.perLanguage }}</router-link>
            </div>
            <div id="localise"><span class="languageLink" @click="switchLanguage('sv')">sv</span> <span class="languageLink" @click="switchLanguage('fi')">fi</span> <span class="languageLink" @click="switchLanguage('en')">en</span></div>
            <div style="clear: both"></div>
        </div>
        <div v-bind:style="{ opacity: isLoading }" id="loading">Laddar...</div>
        <router-view :key="forceReload"></router-view>
        <div id="datasource" class="transitioner">
            <em>{{ strings.sources }}:<br /> <a href="https://statfin.stat.fi/PxWeb/pxweb/sv/StatFin/StatFin__vaerak/statfin_vaerak_pxt_11rm.px/" target="_blank">Statistikcentralens avgiftsfria statistikdatabaser, 11rm -- Språk efter kön kommunvis, 1990-2022</a>, {{updatedDate}}</em>
        </div>
    </div>
</template>

<script>
import {LoFStore} from "../js/LoFStore.js";
import {LoFLoc} from "../js/LoFLoc.js";

export default {
  name: 'LanguagesOfFinland',
  props: {
  },
  data: () => {
      return { 
        title: LoFLoc.translate("titles","languagesOfFinland") +  " 1990 - 2023",
        updatedDate: "2024-06-26",
        availableLanguages: [],
        availablePlaces: [],
        store: {},
        strings: {},
        isLoading: 1,
        forceReload: 0
      }
  },
  mounted: function() {
    if (localStorage.language) {
      LoFLoc.setLanguage(localStorage.language);
    }
    this.loadStrings(); // will load default
  },
  created: function() { this.bootup();  },
  methods: {
    bootup: function() {
        LoFStore.registerCallback(this.onDataLoaded); // function that LoF
        LoFStore.loadData(this);
        this.isLoading = 1;
    },
    loadStrings: function() {
        this.title=  LoFLoc.translate("titles","languagesOfFinland") +  " 1990 - 2023";
        this.strings =  {"overTime": LoFLoc.translate("titles","overTime"), "perLanguage":LoFLoc.translate("titles","perLanguage"), "sources": LoFLoc.translate("titles","sources") };
    },
    reloadRoute: function() {
        this.forceReload += 1;
    },
    onDataLoaded: function() {
            this.isLoading = 0;
            setTimeout(() => {
                
                document.getElementById("loading").style.display = "none";

                this.availableLanguages = LoFStore.availableLanguages;
                this.availablePlaces = LoFStore.availablePlaces;
                this.store = LoFStore.store;

            }, 1000);
    },
    switchLanguage: function(language) {
        //console.log(language);
        LoFLoc.setLanguage(language);
        localStorage.language = language;

        this.loadStrings();
        LoFStore.switchDisplayLangugage();
        
        this.reloadRoute();

     //   this.$forceUpdate();
    }
 
  }
}

</script>
<style>
       body, button, input, select, textarea { font-family: Montserrat; }
       body {
           overflow-x: hidden; 
       }

        h1 {
            background-color: #01035c;
            color: white;
            padding: 10px;
            margin: 0px;
        }

        h4 {
            padding: 0;
            margin-bottom: 10px;
            display: inline-block;
        }

        svg {
            border: 1px solid black;
            cursor: crosshair;
        }


        .footer {
             background-color: #00012c;
            color: white;
            padding: 10px;
            margin: 0px;
        }

        #subchartMenu {
          
            background-color: #00012c;
            color: white;
            padding: 10px;
            margin: 0px;
        }
        
        .submenuLink {
            color: white;
            margin-right: 10px;
        }

        .languageLink {
            color: white;
            margin-right: 10px;
            cursor: pointer;
            text-decoration: underline;
        }

        #chartSettings {
            width: calc(100%-50px);
            margin: 25px;
            text-align: left;
            clear: both;
        }
   

        #loading {
            opacity: 0;
            transition: opacity 1s;
            width: 100%;
            font-weight: bold;
            text-align: center;
            font-size: 88px;
            padding-top: 20%;
            position: absolute;

        }

        .transitioner {
            opacity: 0;
            transition: opacity 0.5s;
        }

        #mainView {
            opacity: 0;
            transition: opacity 1s;
            padding: 5px;
            display: flex;
            margin: 0;
        }

 

        .settingSelect {
            float: left;
            padding-bottom:25px;
            padding-right: 20px;
        }


        #routers {
            float: left;
        }

        #localise {
            float: right;
        }
 

        #datasource {
            width: calc(100%-25px);
            text-align: right;
            margin-top: 25px;
            margin-bottom: 10px;
            padding-right: 25px;
            font-size: 12px;
        }

         .inChartTitle {
            font-size: 14px;
            font-weight: bold;
             text-transform: capitalize;
        }

        .inChartTitleSmaller {
            font-size: 12px;
            font-weight: bold;
             text-transform: capitalize;
        }


        select {
            margin-right: 25px;
            text-transform: capitalize;

        }

        @media only screen and (min-width: 1024px) {
        select {
            width: 300px;
            height: 150px;
        }

        .searchInput {
            width: 292px;
            max-width: 300px;
            margin-bottom: 2px;
        }

        .inChartTitle {
            font-size: 20px;
            font-weight: bold;
             text-transform: capitalize;
        }

        .inChartTitleSmaller {
            font-size: 16px;
            font-weight: bold;
             text-transform: capitalize;
        }

        }

        @media only screen and (max-width: 1023px) {
        h1 {
            background-color: #01035c;
            color: white;
            padding: 10px;
            margin: 0px;
            font-size: 18px;
        }

        #icon {
            width: 28px;
            height: 28px;
        }

        #loading {

            font-size: 48px;

        }

        .submenuLink {
            color: white;
            margin-right: 10px;
            font-size: 12px;
        }

        .searchInput {
            max-width: 120px;
            margin-bottom: 2px;
        }


       .settingSelect {
            float: left;
        }
        }
</style>
