<template>
        <div id="mainView">
            <div id="linechart">
                <div id="chartSettings">
                     <form id="languageSelect" class="settingSelect">
                        <h4>Enhet</h4><br />
                        <input type="radio" name="choice" value="finska" @change="settingsChange($event);" v-model="chartConfig.selectedLanguage"> Finska<br />
                        <input type="radio" name="choice" value="svenska" @change="settingsChange($event);" v-model="chartConfig.selectedLanguage"> Svenska<br />
                        <input type="radio" name="choice" value="samiska" @change="settingsChange($event);" v-model="chartConfig.selectedLanguage"> Samiska<br />
                    </form>
                    <form id="unitSelect" class="settingSelect">
                        <h4>Enhet</h4><br />
                        <input type="radio" name="choice" value="%" @change="settingsChange($event);" v-model="chartConfig.unit"> Procent (%)<br />
                        <input type="radio" name="choice" value="num" @change="settingsChange($event);" v-model="chartConfig.unit"> Antal
                    </form>
                    <div style="clear: both"></div>
                </div>
                <div id="charts"></div>
        </div>
        
        </div>
</template>

<script>
import * as d3 from 'd3';
import {LoFStore} from "../js/LoFStore.js";

export default {
  name: 'LoFPerLanguageDrilldown',
  props: {
  },
  data: () => {
      return { 
        title: "Språk per område",
        store: {},
        availableLanguages: [],
        availablePlaces: [],
        priorityLanguages: ["finska","svenska","samiska"],
        selectedYear: 0,
        chartConfig: {
            type: "line",
            unit: "%",
            limit: 30, // number of areas per chart
            barColor: "#113399",
            selectedLanguage: "finska"
        },
        charts: {},
        window: {
            width: 0,
            height: 0,
            isMobile: false
        },
        isMounted: false // use this to check if the component has been rendered yet - if not, don't attempt adding elements
      }
  },
  created: function() { 
    
    // register the function to be called when data is available;
    LoFStore.registerCallback(this.onDataLoaded);

    window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    mounted: function() {
        this.isMounted = true;
        if (LoFStore.isLoading == 0) { // this means it stopped loading before mounting happened, so no rendering has taken place yet
            document.getElementById("mainView").style.opacity = 1;
            this.showData(this.store, this.chartConfig);
            this.transitionData(1);
        }
    },
  destroyed: function() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize: function() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
            //console.log(this.window.width);
    },
    onDataLoaded: function() {
        
        this.store.data = LoFStore.store.data;
        console.log("data loaded");
        console.log(LoFStore.availableLanguages);
        this.availablePlaces = [...LoFStore.availablePlaces];
        this.availableLanguages = [...LoFStore.availableLanguages]; // clone so we don't modify the source of truth

        this.selectedYear = LoFStore.end_year;

        console.log(this.availableLanguages);

        if (this.isMounted) { // if not mounted, the component hasn't been rendered yet, so there's nothing to write on
            document.getElementById("mainView").style.opacity = 1;
            this.showData(this.store, this.chartConfig);
            this.transitionData(1);
        }
    },
    transitionData: function(opacity) {
        for (let element of document.getElementsByClassName('transitioner')) {
            element.style.opacity = opacity;
        }
    },

    getAreaByName: function(areas, area) {
        let key = 0;
        for (var newkey in areas) {
            if (areas[newkey].area === area) {
                key = newkey;
                break;
            }
        }
        return areas[key];
    },

    // when a change has been made in the selections above, trigger redraw of the chart
    settingsChange: function (/*event*/) {
        this.updateChart(this.store, this.chartConfig); // refresh data        
    }, 

    updateChart: function (store, chartConfig) {
        this.transitionData(0);

        setTimeout(() => {
            this.showData(store, chartConfig);
            setTimeout(() => {this.transitionData(1)}, 250);
        }, 250);

    },
    showData: function(store, chartConfig) {
        this.charts = this.makeChartData(store.data); 
  
        this.drawCharts(this.charts, chartConfig);
    },
    makeChartData: function(areas) {
        let charts = [];
        // first, get the X largest languages by total population

        // then, for each language, find the Y municipalities with the highest proportion, from the selected year
        let languageLargestChart = {name: this.chartConfig.selectedLanguage +"_störst", areas: []};
        languageLargestChart.areas = this.getXestPerLanguage(this.chartConfig.selectedLanguage, areas, true).slice(0,this.chartConfig.limit);
        
        let languageSmallestChart = {name: this.chartConfig.selectedLanguage+"_minst", areas: []};
        languageSmallestChart.areas = this.getXestPerLanguage(this.chartConfig.selectedLanguage, areas, false).slice(0,this.chartConfig.limit);
        
        charts.push(languageLargestChart);
        charts.push(languageSmallestChart);

        return charts;
    },
    getXestPerLanguage: function (languageName, areas, largest) {
        let filteredAreas = [];
        
        for (let area of areas) {
            if (area.area == "Hela landet") continue; // skip it for the moment
            let filteredArea = {};
            filteredArea.name = area.area;
            for (let y in area.years) {
                if (area.years[y].year == this.selectedYear) {
                    filteredArea.totalt = area.years[y].totalt;
                    filteredArea.languageTotal = area.years[y][languageName];
                    filteredArea.languagePercentage = (area.years[y][languageName] / area.years[y].totalt);
                    break;
                }
            }
            filteredAreas.push(filteredArea);
        }

        
            filteredAreas.sort((c1,c2) => {
              if (this.chartConfig.unit == "num") {
                  return c2.languageTotal - c1.languageTotal;
               } else if (this.chartConfig.unit == "%") {
                   return c2.languagePercentage - c1.languagePercentage;
                }
            });

        if (!largest) filteredAreas.reverse();
        
        return filteredAreas;

    },
    drawCharts: function(charts, dataConfig) {
        let config = this.getChartConfig();
        console.log(charts);
        console.log(config);


       let chartsContainer = document.getElementById("charts");
        // clear the charts first
       while (chartsContainer.firstChild) {
           chartsContainer.removeChild(chartsContainer.lastChild);
        }

        for (let chart of charts) {
            // dynamic binding with vue and d3 a bit tricky it seems, so brute forcing in the svg elements
            let svg = document.createElementNS("http://www.w3.org/2000/svg","svg");
            svg.setAttribute("class","språk");
            svg.setAttribute("id","chart_"+chart.name.replaceAll(" ","_")); 
            chartsContainer.appendChild(svg);

            let container = d3.select("#chart_"+chart.name.replaceAll(" ","_"));
            container.selectAll("*").remove(); // clean it first

            let scales = this.getChartScales(chart, config);
            this.drawAxesChart(container, chart, scales, config);
            this.drawFiguresChart(container, chart, scales, config, dataConfig);
        }
    
    },
    getChartConfig: function() { // this config applies for all of the chart
    
        if (this.window.width < 1024) this.window.isMobile = true;

        let width = 650; //this.$refs.app.clientWidth;
        let height = 650;

        let margin = {
            top: 30,
            bottom: 10,
            left: 40,
            right: 20
        };
        //The body is the are that will be occupied by the bars.
        let bodyHeight = height - margin.top - margin.bottom;
        let bodyWidth = width - margin.left - margin.right;

        return { width, height, margin, bodyHeight, bodyWidth };
    },

    getChartScales: function(data, config) {
        let { bodyWidth, bodyHeight  } = config;

        let maximumCount = 0;
        
        if (this.chartConfig.unit == "num") {
            maximumCount = data.areas[0].languageTotal; // since the data is already sorted by size, this should be the largest one
           // maximumCount = maximumCount; // round up
        }
        else if (this.chartConfig.unit == "%") {
            maximumCount = data.areas[0].languagePercentage;
            maximumCount *= 1.01; // add a small cushion
        }

        console.log(maximumCount);

        let yScale = d3.scaleBand()
            .domain(d3.range(data.areas.length))
            .rangeRound([0, bodyHeight])
            .padding(0.5);
            

        let xScale = d3.scaleLinear()
            .rangeRound([0, bodyWidth]) 
            .domain([0, maximumCount]); //Maximum percentage observed
    

        return { xScale, yScale, maximumCount };
    },

    drawFiguresChart: function(container, data, scales, config, dataConfig) {
        let { margin, bodyWidth } = config; // this is equivalent to 'let margin = config.margin; let container = config.container'

        let {xScale, yScale } = scales;
        let body = container.append("g")
            .style("transform", 
            `translate(${margin.left}px,${margin.top}px)`
            );

        body.append("text")
                .attr("x",(bodyWidth/2))            
                .attr("y", 0)
                .attr("font-size",16)
                .attr("font-weight","bold")
                .style("text-anchor", "middle")
                .text(data.name);   
        
        let bars = body.selectAll(".bar").data(data.areas);
        //Adding a rect tag for each year
        bars.enter().append("rect")
            .attr("x", 10)
            .attr("y", (d, i) => { 
                
                return yScale(i);
            })
            .attr("width", (d) =>  {
                            if (dataConfig.unit =="%") return xScale(d.languagePercentage);
                            else if (dataConfig.unit =="num") return xScale( d.languageTotal);
                       } )
            .attr("height", yScale.bandwidth())
            .attr("fill",this.chartConfig.barColor)
            .attr("class","chartBar");
    },

   drawAxesChart: function (container, data, scales, config) {
       
        let { yScale } = scales;
        let {margin  } = config;

        let labels = data.areas.map((d) =>d.name)
        let axisY = d3.axisLeft(yScale)
            .ticks(labels.length)
            .tickFormat((d) => labels[d]);


            
        container.append("g")
            .style("transform", 
            `translate(${margin.left}px,${margin.top}px)`
            )
            .call(axisY);

    },
  }
}

</script>
<style>

        .språk {
            margin-left: 25px;
            margin-bottom: 25px;
            float: left;
            height: 650px;
            width: 650px;
        }

        .chartBar {
            fill: #003366;
            stroke: black;
            stroke-width: 0;
        }
</style>
