import * as d3 from 'd3';
import { LoFLoc } from './LoFLoc';

export var LoFStore = {
    dataset: "/datasets/sprak_per_omrade.csv",
    start_year: 1990,
    end_year: 2023,
    store: {},
    availableLanguages: [],
    availablePlaces: [],
    isLoading: 1,
    callbacks: [],
    registerCallback: function(callback) {
        this.callbacks.push(callback);
        if (this.isLoading == 0) callback(); // if already loaded, call back immediately
    },
    loadData: async function() {
        this.isLoading = 1;

        let promise =d3.csv(this.dataset);
                const data = await promise;
        this.store.data = data;
        //console.log(this.store.data);
        //Get the language date and group by municipality
        this.store.data = this.groupByMunicipality(this.store.data);

        this.availableLanguages = this.getLanguages(this.store.data);
        this.availablePlaces = this.getPlaces(this.store.data);

        //console.log("loadData, done")
      //  console.log(this.availableLanguages);
        for (let callback of this.callbacks) {
            callback();
        }
        this.isLoading = 0;
        return this.store;
    },
    switchDisplayLangugage: function() { // called when the language is switched, so as to get the right transated string
        this.availableLanguages = this.getLanguages(this.store.data);
        this.availablePlaces = this.getPlaces(this.store.data);
    },
    groupByMunicipality: function(data) {
        let result = data.reduce((result, d) => {
            let area  = d["Område"];
            if (d["Område"]=="HELA LANDET") {
                area = "Hela landet";
            }
            let currentData = result[area] || {
                "area": area,
                "isMunicipality": (area.indexOf("MK")!=0),
                "years": {}       
            };

            let newYears = {};
            let newKeys = [];
            for (let i = this.start_year; i <= this.end_year; i++) {
                newKeys.push(""+i);
            }
            for (let yearkey of newKeys) {
                newYears[yearkey] = currentData.years[yearkey] || {"year": yearkey};
                switch (d["Språk"]) {
                    case "Totalt":
                        newYears[yearkey]["totalt"] = parseInt(d[yearkey]) || 0;
                        break;
                    case "FRÄMMANDE SPRÅK TOTALT":
                        newYears[yearkey]["främmande språk totalt"] = parseInt(d[yearkey]) || 0;
                        break;
                    default:
                        newYears[yearkey][d["Språk"]] = parseInt(d[yearkey]) || 0;
                        break;

                }

            }

            currentData.years = newYears; // add the new year to currentData, or replace with updated language data for a group
            result[area] = currentData;
            
            return result;
        }, {});

        //console.log(result);
        // map to list
        result = Object.keys(result).map(key => result[key]);
        for (let area of result) {
            area.years = Object.keys(area.years).map(key => area.years[key]); // also map years
        }

        result = result.sort((c1,c2) => d3.ascending(c1.area,c2.area)); 

        return result;
    },
    getPlaces: function(areas) {
        let places = [];
        let i = 0;
        for (let area of areas) {
            let isMunicipality = true;
            if (area.area.indexOf("MK")==0) {
                isMunicipality = false;
            }
            let areaIndex = {
                name: area.area,
                locName: LoFLoc.translate("regions",area.area),
                population: area.years[area.years.length-1]["totalt"],
                isMunicipality: isMunicipality,
                index: i++
            }
            places.push(areaIndex);
        }
        return places;
    },
    getLanguages: function (areas) {
        let languages = [];
        let i = 0;
        let allCountry = this.getAreaByName(areas, "Hela landet"); // NB this might change
        for (let language of Object.keys(allCountry.years[0])) {
            if (language === "year"|| language === "totalt") continue;
            let languageIndex = {
                name: language,
                locName: LoFLoc.translate("languages",language),
                population: allCountry.years[allCountry.years.length-1][language], // store the most recent
                index: i++
            }
            languages.push(languageIndex);
        }

        return languages;
    },
    getAreaByName: function(areas, area) {
        let key = 0;
        for (var newkey in areas) {
            if (areas[newkey].area === area) {
                key = newkey;
                break;
            }
        }
        return areas[key];
    },
};