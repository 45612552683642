<template>
  <div id="app">
     <img id="icon" src="./assets/strobeicon512.png" />
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  data: () => { return {}}
}
</script>

<style>
#app {
  font-family: Montserrat;;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
            font-family: Montserrat;
            padding: 0px;
            margin: 0;
        }

        h1, h2, h3, h4, h5, h6 {
            font-family: Cabin;
        }

        h1 {
            background-color: #01035c;
            color: white;
            padding: 10px;
            margin: 0px;
        }

        h4 {
            padding: 0;
            margin-bottom: 10px;
        }

        svg {
            border: 1px solid black;
        }

               #icon {
            width: 50px;
            position: absolute;
            top: 5px;
            right: 5px;
        }
</style>
