export var LoFLoc = {
    translate: function(section, string) {
        if (this[section] != undefined && this[section][string] != undefined) {
            if  (this[section][string][this.selectedLanguage] != undefined) {
                return this[section][string][this.selectedLanguage];
            } 
            else return this[section][string][this.default]; // use default language
        }
        
        return "UNKNOWN >> " + this.selectedLanguage + " >> " + string;
    },
    setLanguage: function(language) {
        if (this.availableLanguages.includes(language)) {
            this.selectedLanguage = language;
        }
    },
    "availableLanguages": ["sv","fi","en"],
    "selectedLanguage": "sv",
    "default": "sv",
    "titles": {
        "languagesOfFinland": {"sv": "Språk i Finland","fi": "Kielet Suomessa", "en": "Languages of Finland"},
        "overTime": {"sv": "Befolkningsutveckling för olika språkgrupper", "en": "Population change for different language groups", "fi": "Väestönkehitys eri kieliryhmille" },
        "overTimeChart": {"sv": "Befolkningsutveckling", "fi": "Väestönkehitys", "en": "Population change" },
        "perLanguage": {"sv": "Det största främmande språken i olika kommuner och landskap", "fi": "Suurimmat vieraat kielet eri kunnissa ja maakunnissa", "en": "The largest foreign languages in different municipalities and provinces"},
        "number": {"sv": "Antal","fi": "Määrä", "en": "Number"},
        "percent": {"sv": "Procent","fi": "Prosentti", "en": "Percent"},
        "unit": {"sv": "Enhet","fi": "Yksikkö", "en": "Unit"},
        "language": {"sv": "Språk","fi": "Kieli", "en": "Language"},
        "area": {"sv": "Ort","fi": "Paikkakunta", "en": "Area"},
        "municipality": {"sv": "Kommun","fi": "Kunta", "en": "Municipality"},
        "region": {"sv": "Landskap","fi": "Maakunta", "en": "Region"},
        "sources": {"sv": "Källor", "fi": "Lähteet", "en": "Sources"},
        "years": {"sv": "Årtal", "en": "Years", "fi": "Vuosikaudet"},
        "year": {"sv": "År", "en": "Year", "fi": "Vuosi"},
        "numberOfPersons": {"sv": "Antal personer", "fi": "Määrä ihmistä", "en": "Number of persons"},
        "percentOfPopulation": {"sv": "Procent av befolkning", "fi": "Prosentti", "en": "Percent of total population"}
    },
    "regions": {
        "Ackas": {"sv": "Ackas", "fi": "Akaa", "en": "Akaa"},
        "Alajärvi": {"sv": "Alajärvi", "fi": "Alajärvi", "en": "Alajärvi"},
        "Alavieska": {"sv": "Alavieska", "fi": "Alavieska", "en": "Alavieska"},
        "Alavo": {"sv": "Alavo", "fi": "Alavus", "en": "Alavus"},
        "Asikkala": {"sv": "Asikkala", "fi": "Asikkala", "en": "Asikkala"},
        "Askola": {"sv": "Askola", "fi": "Askola", "en": "Askola"},
        "Aura": {"sv": "Aura", "fi": "Aura", "en": "Aura"},
        "Brändö": {"sv": "Brändö", "fi": "Brändö", "en": "Brändö"},
        "Eckerö": {"sv": "Eckerö", "fi": "Eckerö", "en": "Eckerö"},
        "Enonkoski": {"sv": "Enonkoski", "fi": "Enonkoski", "en": "Enonkoski"},
        "Enontekis": {"sv": "Enontekis", "fi": "Enontekiö", "en": "Enontekiö"},
        "Esbo": {"sv": "Esbo", "fi": "Espoo", "en": "Espoo"},
        "Eura": {"sv": "Eura", "fi": "Eura", "en": "Eura"},
        "Euraåminne": {"sv": "Euraåminne", "fi": "Eurajoki", "en": "Eurajoki"},
        "Evijärvi": {"sv": "Evijärvi", "fi": "Evijärvi", "en": "Evijärvi"},
        "Finström": {"sv": "Finström", "fi": "Finström", "en": "Finström"},
        "Forssa": {"sv": "Forssa", "fi": "Forssa", "en": "Forssa"},
        "Föglö": {"sv": "Föglö", "fi": "Föglö", "en": "Föglö"},
        "Geta": {"sv": "Geta", "fi": "Geta", "en": "Geta"},
        "Haapajärvi": {"sv": "Haapajärvi", "fi": "Haapajärvi", "en": "Haapajärvi"},
        "Haapavesi": {"sv": "Haapavesi", "fi": "Haapavesi", "en": "Haapavesi"},
        "Karlö": {"sv": "Karlö", "fi": "Hailuoto", "en": "Hailuoto"},
        "Halso": {"sv": "Halso", "fi": "Halsua", "en": "Halsua"},
        "Fredrikshamn": {"sv": "Fredrikshamn", "fi": "Hamina", "en": "Hamina"},
        "Hammarland": {"sv": "Hammarland", "fi": "Hammarland", "en": "Hammarland"},
        "Hankasalmi": {"sv": "Hankasalmi", "fi": "Hankasalmi", "en": "Hankasalmi"},
        "Hangö": {"sv": "Hangö", "fi": "Hanko", "en": "Hanko"},
        "Harjavalta": {"sv": "Harjavalta", "fi": "Harjavalta", "en": "Harjavalta"},
        "Gustav Adolfs": {"sv": "Gustav Adolfs", "fi": "Hartola", "en": "Hartola"},
        "Hattula": {"sv": "Hattula", "fi": "Hattula", "en": "Hattula"},
        "Hausjärvi": {"sv": "Hausjärvi", "fi": "Hausjärvi", "en": "Hausjärvi"},
        "Heinola": {"sv": "Heinola", "fi": "Heinola", "en": "Heinola"},
        "Heinävesi": {"sv": "Heinävesi", "fi": "Heinävesi", "en": "Heinävesi"},
        "Helsingfors": {"sv": "Helsingfors", "fi": "Helsinki", "en": "Helsinki"},
        "Hirvensalmi": {"sv": "Hirvensalmi", "fi": "Hirvensalmi", "en": "Hirvensalmi"},
        "Hollola": {"sv": "Hollola", "fi": "Hollola", "en": "Hollola"},
        "Honkajoki": {"sv": "Honkajoki", "fi": "Honkajoki", "en": "Honkajoki"},
        "Vittis": {"sv": "Vittis", "fi": "Huittinen", "en": "Huittinen"},
        "Humppila": {"sv": "Humppila", "fi": "Humppila", "en": "Humppila"},
        "Hyrynsalmi": {"sv": "Hyrynsalmi", "fi": "Hyrynsalmi", "en": "Hyrynsalmi"},
        "Hyvinge": {"sv": "Hyvinge", "fi": "Hyvinkää", "en": "Hyvinkää"},
        "Tavastkyro": {"sv": "Tavastkyro", "fi": "Hämeenkyrö", "en": "Hämeenkyrö"},
        "Tavastehus": {"sv": "Tavastehus", "fi": "Hämeenlinna", "en": "Hämeenlinna"},
        "Ijo": {"sv": "Ijo", "fi": "Ii", "en": "Ii"},
        "Idensalmi": {"sv": "Idensalmi", "fi": "Iisalmi", "en": "Iisalmi"},
        "Itis": {"sv": "Itis", "fi": "Iitti", "en": "Iitti"},
        "Ikalis": {"sv": "Ikalis", "fi": "Ikaalinen", "en": "Ikaalinen"},
        "Ilmola": {"sv": "Ilmola", "fi": "Ilmajoki", "en": "Ilmajoki"},
        "Ilomants": {"sv": "Ilomants", "fi": "Ilomantsi", "en": "Ilomantsi"},
        "Imatra": {"sv": "Imatra", "fi": "Imatra", "en": "Imatra"},
        "Enare": {"sv": "Enare", "fi": "Inari", "en": "Inari"},
        "Ingå": {"sv": "Ingå", "fi": "Inkoo", "en": "Ingå"},
        "Storå": {"sv": "Storå", "fi": "Isojoki", "en": "Isojoki"},
        "Storkyro": {"sv": "Storkyro", "fi": "Isokyrö", "en": "Isokyrö"},
        "Jakobstad": {"sv": "Jakobstad", "fi": "Pietarsaari", "en": "Jakobstad"},
        "Janakkala": {"sv": "Janakkala", "fi": "Janakkala", "en": "Janakkala"},
        "Joensuu": {"sv": "Joensuu", "fi": "Joensuu", "en": "Joensuu"},
        "Jockis": {"sv": "Jockis", "fi": "Jokioinen", "en": "Jokioinen"},
        "Jomala": {"sv": "Jomala", "fi": "Jomala", "en": "Jomala"},
        "Jorois": {"sv": "Jorois", "fi": "Joroinen", "en": "Joroinen"},
        "Joutsa": {"sv": "Joutsa", "fi": "Joutsa", "en": "Joutsa"},
        "Juga": {"sv": "Juga", "fi": "Juuka", "en": "Juuka"},
        "Juupajoki": {"sv": "Juupajoki", "fi": "Juupajoki", "en": "Juupajoki"},
        "Juva": {"sv": "Juva", "fi": "Juva", "en": "Juva"},
        "Jyväskylä": {"sv": "Jyväskylä", "fi": "Jyväskylä", "en": "Jyväskylä"},
        "Jämijärvi": {"sv": "Jämijärvi", "fi": "Jämijärvi", "en": "Jämijärvi"},
        "Jämsä": {"sv": "Jämsä", "fi": "Jämsä", "en": "Jämsä"},
        "Träskända": {"sv": "Träskända", "fi": "Järvenpää", "en": "Järvenpää"},
        "S:t Karins": {"sv": "S:t Karins", "fi": "Kaarina", "en": "Kaarina"},
        "Kaavi": {"sv": "Kaavi", "fi": "Kaavi", "en": "Kaavi"},
        "Kajana": {"sv": "Kajana", "fi": "Kajaani", "en": "Kajaani"},
        "Kalajoki": {"sv": "Kalajoki", "fi": "Kalajoki", "en": "Kalajoki"},
        "Kangasala": {"sv": "Kangasala", "fi": "Kangasala", "en": "Kangasala"},
        "Kangasniemi": {"sv": "Kangasniemi", "fi": "Kangasniemi", "en": "Kangasniemi"},
        "Kankaanpää": {"sv": "Kankaanpää", "fi": "Kankaanpää", "en": "Kankaanpää"},
        "Kannonkoski": {"sv": "Kannonkoski", "fi": "Kannonkoski", "en": "Kannonkoski"},
        "Kannus": {"sv": "Kannus", "fi": "Kannus", "en": "Kannus"},
        "Bötom": {"sv": "Bötom", "fi": "Karijoki", "en": "Karijoki"},
        "Högfors": {"sv": "Högfors", "fi": "Karkkila", "en": "Karkkila"},
        "Karstula": {"sv": "Karstula", "fi": "Karstula", "en": "Karstula"},
        "Karvia": {"sv": "Karvia", "fi": "Karvia", "en": "Karvia"},
        "Kaskö": {"sv": "Kaskö", "fi": "Kaskinen", "en": "Kaskinen"},
        "Kauhajoki": {"sv": "Kauhajoki", "fi": "Kauhajoki", "en": "Kauhajoki"},
        "Kauhava": {"sv": "Kauhava", "fi": "Kauhava", "en": "Kauhava"},
        "Grankulla": {"sv": "Grankulla", "fi": "Kauniainen", "en": "Kauniainen"},
        "Kaustby": {"sv": "Kaustby", "fi": "Kaustinen", "en": "Kaustinen"},
        "Keitele": {"sv": "Keitele", "fi": "Keitele", "en": "Keitele"},
        "Kemi": {"sv": "Kemi", "fi": "Kemi", "en": "Kemi"},
        "Kemijärvi": {"sv": "Kemijärvi", "fi": "Kemijärvi", "en": "Kemijärvi"},
        "Keminmaa": {"sv": "Keminmaa", "fi": "Keminmaa", "en": "Keminmaa"},
        "Kempele": {"sv": "Kempele", "fi": "Kempele", "en": "Kempele"},
        "Kervo": {"sv": "Kervo", "fi": "Kerava", "en": "Kerava"},
        "Keuru": {"sv": "Keuru", "fi": "Keuruu", "en": "Keuruu"},
        "Kihniö": {"sv": "Kihniö", "fi": "Kihniö", "en": "Kihniö"},
        "Kimitoön": {"sv": "Kimitoön", "fi": "Kemiönsaari", "en": "Kimitoön"},
        "Kinnula": {"sv": "Kinnula", "fi": "Kinnula", "en": "Kinnula"},
        "Kyrkslätt": {"sv": "Kyrkslätt", "fi": "Kirkkonummi", "en": "Kirkkonummi"},
        "Kides": {"sv": "Kides", "fi": "Kitee", "en": "Kitee"},
        "Kittilä": {"sv": "Kittilä", "fi": "Kittilä", "en": "Kittilä"},
        "Kiuruvesi": {"sv": "Kiuruvesi", "fi": "Kiuruvesi", "en": "Kiuruvesi"},
        "Kivijärvi": {"sv": "Kivijärvi", "fi": "Kivijärvi", "en": "Kivijärvi"},
        "Kumo": {"sv": "Kumo", "fi": "Kokemäki", "en": "Kokemäki"},
        "Karleby": {"sv": "Karleby", "fi": "Kokkola", "en": "Kokkola"},
        "Kolari": {"sv": "Kolari", "fi": "Kolari", "en": "Kolari"},
        "Konnevesi": {"sv": "Konnevesi", "fi": "Konnevesi", "en": "Konnevesi"},
        "Kontiolax": {"sv": "Kontiolax", "fi": "Kontiolahti", "en": "Kontiolahti"},
        "Korsholm": {"sv": "Korsholm", "fi": "Mustasaari", "en": "Korsholm"},
        "Korsnäs": {"sv": "Korsnäs", "fi": "Korsnäs", "en": "Korsnäs"},
        "Koskis": {"sv": "Koskis", "fi": "Koski Tl", "en": "Koski Tl"},
        "Kotka": {"sv": "Kotka", "fi": "Kotka", "en": "Kotka"},
        "Kouvola": {"sv": "Kouvola", "fi": "Kouvola", "en": "Kouvola"},
        "Kristinestad": {"sv": "Kristinestad", "fi": "Kristiinankaupunki", "en": "Kristinestad"},
        "Kronoby": {"sv": "Kronoby", "fi": "Kruunupyy", "en": "Kronoby"},
        "Kuhmo": {"sv": "Kuhmo", "fi": "Kuhmo", "en": "Kuhmo"},
        "Kuhmois": {"sv": "Kuhmois", "fi": "Kuhmoinen", "en": "Kuhmoinen"},
        "Kumlinge": {"sv": "Kumlinge", "fi": "Kumlinge", "en": "Kumlinge"},
        "Kuopio": {"sv": "Kuopio", "fi": "Kuopio", "en": "Kuopio"},
        "Kuortane": {"sv": "Kuortane", "fi": "Kuortane", "en": "Kuortane"},
        "Kurikka": {"sv": "Kurikka", "fi": "Kurikka", "en": "Kurikka"},
        "Gustavs": {"sv": "Gustavs", "fi": "Kustavi", "en": "Kustavi"},
        "Kuusamo": {"sv": "Kuusamo", "fi": "Kuusamo", "en": "Kuusamo"},
        "Kyyjärvi": {"sv": "Kyyjärvi", "fi": "Kyyjärvi", "en": "Kyyjärvi"},
        "Kärkölä": {"sv": "Kärkölä", "fi": "Kärkölä", "en": "Kärkölä"},
        "Kärsämäki": {"sv": "Kärsämäki", "fi": "Kärsämäki", "en": "Kärsämäki"},
        "Kökar": {"sv": "Kökar", "fi": "Kökar", "en": "Kökar"},
        "Lahtis": {"sv": "Lahtis", "fi": "Lahti", "en": "Lahti"},
        "Laihela": {"sv": "Laihela", "fi": "Laihia", "en": "Laihia"},
        "Letala": {"sv": "Letala", "fi": "Laitila", "en": "Laitila"},
        "Lappträsk": {"sv": "Lappträsk", "fi": "Lapinjärvi", "en": "Lapinjärvi"},
        "Lapinlahti": {"sv": "Lapinlahti", "fi": "Lapinlahti", "en": "Lapinlahti"},
        "Lappajärvi": {"sv": "Lappajärvi", "fi": "Lappajärvi", "en": "Lappajärvi"},
        "Villmanstrand": {"sv": "Villmanstrand", "fi": "Lappeenranta", "en": "Lappeenranta"},
        "Lappo": {"sv": "Lappo", "fi": "Lapua", "en": "Lapua"},
        "Larsmo": {"sv": "Larsmo", "fi": "Luoto", "en": "Larsmo"},
        "Laukas": {"sv": "Laukas", "fi": "Laukaa", "en": "Laukaa"},
        "Lemi": {"sv": "Lemi", "fi": "Lemi", "en": "Lemi"},
        "Lemland": {"sv": "Lemland", "fi": "Lemland", "en": "Lemland"},
        "Lempäälä": {"sv": "Lempäälä", "fi": "Lempäälä", "en": "Lempäälä"},
        "Leppävirta": {"sv": "Leppävirta", "fi": "Leppävirta", "en": "Leppävirta"},
        "Lestijärvi": {"sv": "Lestijärvi", "fi": "Lestijärvi", "en": "Lestijärvi"},
        "Lieksa": {"sv": "Lieksa", "fi": "Lieksa", "en": "Lieksa"},
        "Lundo": {"sv": "Lundo", "fi": "Lieto", "en": "Lieto"},
        "Limingo": {"sv": "Limingo", "fi": "Liminka", "en": "Liminka"},
        "Libelits": {"sv": "Libelits", "fi": "Liperi", "en": "Liperi"},
        "Lojo": {"sv": "Lojo", "fi": "Lohja", "en": "Lohja"},
        "Loimaa": {"sv": "Loimaa", "fi": "Loimaa", "en": "Loimaa"},
        "Loppi": {"sv": "Loppi", "fi": "Loppi", "en": "Loppi"},
        "Lovisa": {"sv": "Lovisa", "fi": "Loviisa", "en": "Loviisa"},
        "Luhanka": {"sv": "Luhanka", "fi": "Luhanka", "en": "Luhanka"},
        "Lumijoki": {"sv": "Lumijoki", "fi": "Lumijoki", "en": "Lumijoki"},
        "Lumparland": {"sv": "Lumparland", "fi": "Lumparland", "en": "Lumparland"},
        "Luumäki": {"sv": "Luumäki", "fi": "Luumäki", "en": "Luumäki"},
        "Malax": {"sv": "Malax", "fi": "Maalahti", "en": "Malax"},
        "Mariehamn": {"sv": "Mariehamn", "fi": "Maarianhamina", "en": "Mariehamn"},
        "S:t Mårtens": {"sv": "S:t Mårtens", "fi": "Marttila", "en": "Marttila"},
        "Masku": {"sv": "Masku", "fi": "Masku", "en": "Masku"},
        "Merijärvi": {"sv": "Merijärvi", "fi": "Merijärvi", "en": "Merijärvi"},
        "Sastmola": {"sv": "Sastmola", "fi": "Merikarvia", "en": "Merikarvia"},
        "Miehikkälä": {"sv": "Miehikkälä", "fi": "Miehikkälä", "en": "Miehikkälä"},
        "S:t Michel": {"sv": "S:t Michel", "fi": "Mikkeli", "en": "Mikkeli"},
        "Muhos": {"sv": "Muhos", "fi": "Muhos", "en": "Muhos"},
        "Multia": {"sv": "Multia", "fi": "Multia", "en": "Multia"},
        "Muonio": {"sv": "Muonio", "fi": "Muonio", "en": "Muonio"},
        "Muurame": {"sv": "Muurame", "fi": "Muurame", "en": "Muurame"},
        "Virmo": {"sv": "Virmo", "fi": "Mynämäki", "en": "Mynämäki"},
        "Mörskom": {"sv": "Mörskom", "fi": "Myrskylä", "en": "Myrskylä"},
        "Mäntsälä": {"sv": "Mäntsälä", "fi": "Mäntsälä", "en": "Mäntsälä"},
        "Mänttä-Vilppula": {"sv": "Mänttä-Vilppula", "fi": "Mänttä-Vilppula", "en": "Mänttä-Vilppula"},
        "Mäntyharju": {"sv": "Mäntyharju", "fi": "Mäntyharju", "en": "Mäntyharju"},
        "Nådendal": {"sv": "Nådendal", "fi": "Naantali", "en": "Naantali"},
        "Nakkila": {"sv": "Nakkila", "fi": "Nakkila", "en": "Nakkila"},
        "Nivala": {"sv": "Nivala", "fi": "Nivala", "en": "Nivala"},
        "Nokia": {"sv": "Nokia", "fi": "Nokia", "en": "Nokia"},
        "Nousis": {"sv": "Nousis", "fi": "Nousiainen", "en": "Nousiainen"},
        "Nurmes": {"sv": "Nurmes", "fi": "Nurmes", "en": "Nurmes"},
        "Nurmijärvi": {"sv": "Nurmijärvi", "fi": "Nurmijärvi", "en": "Nurmijärvi"},
        "Nykarleby": {"sv": "Nykarleby", "fi": "Uusikaarlepyy", "en": "Nykarleby"},
        "Närpes": {"sv": "Närpes", "fi": "Närpiö", "en": "Närpes"},
        "Orimattila": {"sv": "Orimattila", "fi": "Orimattila", "en": "Orimattila"},
        "Oripää": {"sv": "Oripää", "fi": "Oripää", "en": "Oripää"},
        "Orivesi": {"sv": "Orivesi", "fi": "Orivesi", "en": "Orivesi"},
        "Oulainen": {"sv": "Oulainen", "fi": "Oulainen", "en": "Oulainen"},
        "Uleåborg": {"sv": "Uleåborg", "fi": "Oulu", "en": "Oulu"},
        "Outokumpu": {"sv": "Outokumpu", "fi": "Outokumpu", "en": "Outokumpu"},
        "Padasjoki": {"sv": "Padasjoki", "fi": "Padasjoki", "en": "Padasjoki"},
        "Pemar": {"sv": "Pemar", "fi": "Paimio", "en": "Paimio"},
        "Paltamo": {"sv": "Paltamo", "fi": "Paltamo", "en": "Paltamo"},
        "Pargas": {"sv": "Pargas", "fi": "Parainen", "en": "Pargas"},
        "Parikkala": {"sv": "Parikkala", "fi": "Parikkala", "en": "Parikkala"},
        "Parkano": {"sv": "Parkano", "fi": "Parkano", "en": "Parkano"},
        "Pedersöre": {"sv": "Pedersöre", "fi": "Pedersören kunta", "en": "Pedersöre"},
        "Pelkosenniemi": {"sv": "Pelkosenniemi", "fi": "Pelkosenniemi", "en": "Pelkosenniemi"},
        "Pello": {"sv": "Pello", "fi": "Pello", "en": "Pello"},
        "Perho": {"sv": "Perho", "fi": "Perho", "en": "Perho"},
        "Pertunmaa": {"sv": "Pertunmaa", "fi": "Pertunmaa", "en": "Pertunmaa"},
        "Petäjävesi": {"sv": "Petäjävesi", "fi": "Petäjävesi", "en": "Petäjävesi"},
        "Pieksämäki": {"sv": "Pieksämäki", "fi": "Pieksämäki", "en": "Pieksämäki"},
        "Pielavesi": {"sv": "Pielavesi", "fi": "Pielavesi", "en": "Pielavesi"},
        "Pihtipudas": {"sv": "Pihtipudas", "fi": "Pihtipudas", "en": "Pihtipudas"},
        "Birkala": {"sv": "Birkala", "fi": "Pirkkala", "en": "Pirkkala"},
        "Polvijärvi": {"sv": "Polvijärvi", "fi": "Polvijärvi", "en": "Polvijärvi"},
        "Påmark": {"sv": "Påmark", "fi": "Pomarkku", "en": "Pomarkku"},
        "Björneborg": {"sv": "Björneborg", "fi": "Pori", "en": "Pori"},
        "Borgnäs": {"sv": "Borgnäs", "fi": "Pornainen", "en": "Pornainen"},
        "Borgå": {"sv": "Borgå", "fi": "Porvoo", "en": "Porvoo"},
        "Posio": {"sv": "Posio", "fi": "Posio", "en": "Posio"},
        "Pudasjärvi": {"sv": "Pudasjärvi", "fi": "Pudasjärvi", "en": "Pudasjärvi"},
        "Pukkila": {"sv": "Pukkila", "fi": "Pukkila", "en": "Pukkila"},
        "Punkalaidun": {"sv": "Punkalaidun", "fi": "Punkalaidun", "en": "Punkalaidun"},
        "Puolanka": {"sv": "Puolanka", "fi": "Puolanka", "en": "Puolanka"},
        "Puumala": {"sv": "Puumala", "fi": "Puumala", "en": "Puumala"},
        "Pyttis": {"sv": "Pyttis", "fi": "Pyhtää", "en": "Pyhtää"},
        "Pyhäjoki": {"sv": "Pyhäjoki", "fi": "Pyhäjoki", "en": "Pyhäjoki"},
        "Pyhäjärvi": {"sv": "Pyhäjärvi", "fi": "Pyhäjärvi", "en": "Pyhäjärvi"},
        "Pyhäntä": {"sv": "Pyhäntä", "fi": "Pyhäntä", "en": "Pyhäntä"},
        "Pyhäranta": {"sv": "Pyhäranta", "fi": "Pyhäranta", "en": "Pyhäranta"},
        "Pälkäne": {"sv": "Pälkäne", "fi": "Pälkäne", "en": "Pälkäne"},
        "Pöytyä": {"sv": "Pöytyä", "fi": "Pöytyä", "en": "Pöytyä"},
        "Brahestad": {"sv": "Brahestad", "fi": "Raahe", "en": "Raahe"},
        "Reso": {"sv": "Reso", "fi": "Raisio", "en": "Raisio"},
        "Rantasalmi": {"sv": "Rantasalmi", "fi": "Rantasalmi", "en": "Rantasalmi"},
        "Ranua": {"sv": "Ranua", "fi": "Ranua", "en": "Ranua"},
        "Raseborg": {"sv": "Raseborg", "fi": "Raasepori", "en": "Raseborg"},
        "Raumo": {"sv": "Raumo", "fi": "Rauma", "en": "Rauma"},
        "Rautalampi": {"sv": "Rautalampi", "fi": "Rautalampi", "en": "Rautalampi"},
        "Rautavaara": {"sv": "Rautavaara", "fi": "Rautavaara", "en": "Rautavaara"},
        "Rautjärvi": {"sv": "Rautjärvi", "fi": "Rautjärvi", "en": "Rautjärvi"},
        "Reisjärvi": {"sv": "Reisjärvi", "fi": "Reisjärvi", "en": "Reisjärvi"},
        "Riihimäki": {"sv": "Riihimäki", "fi": "Riihimäki", "en": "Riihimäki"},
        "Ristijärvi": {"sv": "Ristijärvi", "fi": "Ristijärvi", "en": "Ristijärvi"},
        "Rovaniemi": {"sv": "Rovaniemi", "fi": "Rovaniemi", "en": "Rovaniemi"},
        "Ruokolax": {"sv": "Ruokolax", "fi": "Ruokolahti", "en": "Ruokolahti"},
        "Ruovesi": {"sv": "Ruovesi", "fi": "Ruovesi", "en": "Ruovesi"},
        "Rusko": {"sv": "Rusko", "fi": "Rusko", "en": "Rusko"},
        "Rääkkylä": {"sv": "Rääkkylä", "fi": "Rääkkylä", "en": "Rääkkylä"},
        "Saarijärvi": {"sv": "Saarijärvi", "fi": "Saarijärvi", "en": "Saarijärvi"},
        "Salla": {"sv": "Salla", "fi": "Salla", "en": "Salla"},
        "Salo": {"sv": "Salo", "fi": "Salo", "en": "Salo"},
        "Saltvik": {"sv": "Saltvik", "fi": "Saltvik", "en": "Saltvik"},
        "Sastamala": {"sv": "Sastamala", "fi": "Sastamala", "en": "Sastamala"},
        "Sagu": {"sv": "Sagu", "fi": "Sauvo", "en": "Sauvo"},
        "Savitaipale": {"sv": "Savitaipale", "fi": "Savitaipale", "en": "Savitaipale"},
        "Nyslott": {"sv": "Nyslott", "fi": "Savonlinna", "en": "Savonlinna"},
        "Savukoski": {"sv": "Savukoski", "fi": "Savukoski", "en": "Savukoski"},
        "Seinäjoki": {"sv": "Seinäjoki", "fi": "Seinäjoki", "en": "Seinäjoki"},
        "Sievi": {"sv": "Sievi", "fi": "Sievi", "en": "Sievi"},
        "Siikais": {"sv": "Siikais", "fi": "Siikainen", "en": "Siikainen"},
        "Siikajoki": {"sv": "Siikajoki", "fi": "Siikajoki", "en": "Siikajoki"},
        "Siikalatva": {"sv": "Siikalatva", "fi": "Siikalatva", "en": "Siikalatva"},
        "Siilinjärvi": {"sv": "Siilinjärvi", "fi": "Siilinjärvi", "en": "Siilinjärvi"},
        "Simo": {"sv": "Simo", "fi": "Simo", "en": "Simo"},
        "Sibbo": {"sv": "Sibbo", "fi": "Sipoo", "en": "Sipoo"},
        "Sjundeå": {"sv": "Sjundeå", "fi": "Siuntio", "en": "Siuntio"},
        "Sodankylä": {"sv": "Sodankylä", "fi": "Sodankylä", "en": "Sodankylä"},
        "Soini": {"sv": "Soini", "fi": "Soini", "en": "Soini"},
        "Somero": {"sv": "Somero", "fi": "Somero", "en": "Somero"},
        "Sonkajärvi": {"sv": "Sonkajärvi", "fi": "Sonkajärvi", "en": "Sonkajärvi"},
        "Sotkamo": {"sv": "Sotkamo", "fi": "Sotkamo", "en": "Sotkamo"},
        "Sottunga": {"sv": "Sottunga", "fi": "Sottunga", "en": "Sottunga"},
        "Sulkava": {"sv": "Sulkava", "fi": "Sulkava", "en": "Sulkava"},
        "Sund": {"sv": "Sund", "fi": "Sund", "en": "Sund"},
        "Suomussalmi": {"sv": "Suomussalmi", "fi": "Suomussalmi", "en": "Suomussalmi"},
        "Suonenjoki": {"sv": "Suonenjoki", "fi": "Suonenjoki", "en": "Suonenjoki"},
        "Sysmä": {"sv": "Sysmä", "fi": "Sysmä", "en": "Sysmä"},
        "Säkylä": {"sv": "Säkylä", "fi": "Säkylä", "en": "Säkylä"},
        "Taipalsaari": {"sv": "Taipalsaari", "fi": "Taipalsaari", "en": "Taipalsaari"},
        "Taivalkoski": {"sv": "Taivalkoski", "fi": "Taivalkoski", "en": "Taivalkoski"},
        "Tövsala": {"sv": "Tövsala", "fi": "Taivassalo", "en": "Taivassalo"},
        "Tammela": {"sv": "Tammela", "fi": "Tammela", "en": "Tammela"},
        "Tammerfors": {"sv": "Tammerfors", "fi": "Tampere", "en": "Tampere"},
        "Tervo": {"sv": "Tervo", "fi": "Tervo", "en": "Tervo"},
        "Tervola": {"sv": "Tervola", "fi": "Tervola", "en": "Tervola"},
        "Östermark": {"sv": "Östermark", "fi": "Teuva", "en": "Teuva"},
        "Tohmajärvi": {"sv": "Tohmajärvi", "fi": "Tohmajärvi", "en": "Tohmajärvi"},
        "Toholampi": {"sv": "Toholampi", "fi": "Toholampi", "en": "Toholampi"},
        "Toivakka": {"sv": "Toivakka", "fi": "Toivakka", "en": "Toivakka"},
        "Torneå": {"sv": "Torneå", "fi": "Tornio", "en": "Tornio"},
        "Åbo": {"sv": "Åbo", "fi": "Turku", "en": "Turku"},
        "Tuusniemi": {"sv": "Tuusniemi", "fi": "Tuusniemi", "en": "Tuusniemi"},
        "Tusby": {"sv": "Tusby", "fi": "Tuusula", "en": "Tuusula"},
        "Tyrnävä": {"sv": "Tyrnävä", "fi": "Tyrnävä", "en": "Tyrnävä"},
        "Ulvsby": {"sv": "Ulvsby", "fi": "Ulvila", "en": "Ulvila"},
        "Urjala": {"sv": "Urjala", "fi": "Urjala", "en": "Urjala"},
        "Utajärvi": {"sv": "Utajärvi", "fi": "Utajärvi", "en": "Utajärvi"},
        "Utsjoki": {"sv": "Utsjoki", "fi": "Utsjoki", "en": "Utsjoki"},
        "Uurainen": {"sv": "Uurainen", "fi": "Uurainen", "en": "Uurainen"},
        "Nystad": {"sv": "Nystad", "fi": "Uusikaupunki", "en": "Uusikaupunki"},
        "Vaala": {"sv": "Vaala", "fi": "Vaala", "en": "Vaala"},
        "Vasa": {"sv": "Vasa", "fi": "Vaasa", "en": "Vaasa"},
        "Valkeakoski": {"sv": "Valkeakoski", "fi": "Valkeakoski", "en": "Valkeakoski"},
        "Vanda": {"sv": "Vanda", "fi": "Vantaa", "en": "Vantaa"},
        "Varkaus": {"sv": "Varkaus", "fi": "Varkaus", "en": "Varkaus"},
        "Vemo": {"sv": "Vemo", "fi": "Vehmaa", "en": "Vehmaa"},
        "Vesanto": {"sv": "Vesanto", "fi": "Vesanto", "en": "Vesanto"},
        "Vesilahti": {"sv": "Vesilahti", "fi": "Vesilahti", "en": "Vesilahti"},
        "Vetil": {"sv": "Vetil", "fi": "Veteli", "en": "Veteli"},
        "Vieremä": {"sv": "Vieremä", "fi": "Vieremä", "en": "Vieremä"},
        "Vichtis": {"sv": "Vichtis", "fi": "Vihti", "en": "Vihti"},
        "Viitasaari": {"sv": "Viitasaari", "fi": "Viitasaari", "en": "Viitasaari"},
        "Vindala": {"sv": "Vindala", "fi": "Vimpeli", "en": "Vimpeli"},
        "Vederlax": {"sv": "Vederlax", "fi": "Virolahti", "en": "Virolahti"},
        "Virdois": {"sv": "Virdois", "fi": "Virrat", "en": "Virrat"},
        "Vårdö": {"sv": "Vårdö", "fi": "Vårdö", "en": "Vårdö"},
        "Vörå": {"sv": "Vörå", "fi": "Vöyri", "en": "Vörå"},
        "Övertorneå": {"sv": "Övertorneå", "fi": "Ylitornio", "en": "Ylitornio"},
        "Ylivieska": {"sv": "Ylivieska", "fi": "Ylivieska", "en": "Ylivieska"},
        "Ylöjärvi": {"sv": "Ylöjärvi", "fi": "Ylöjärvi", "en": "Ylöjärvi"},
        "Ypäjä": {"sv": "Ypäjä", "fi": "Ypäjä", "en": "Ypäjä"},
        "Etseri": {"sv": "Etseri", "fi": "Ähtäri", "en": "Ähtäri"},
        "Äänekoski": {"sv": "Äänekoski", "fi": "Äänekoski", "en": "Äänekoski"},
        "MK19 Lappland": {"sv": "Lappland", "fi": "Lappi", "en": "Lapland"},
        "MK17 Norra Österbotten": {"sv": "Norra Österbotten", "fi": "Pohjois-Pohjanmaa", "en": "North Ostrobothnia"},
        "MK18 Kajanaland": {"sv": "Kajanaland", "fi": "Kainuu", "en": "Kainuu"},
        "MK12 Norra Karelen": {"sv": "Norra Karelen", "fi": "Pohjois-Karjala", "en": "North Karelia"},
        "MK11 Norra Savolax": {"sv": "Norra Savolax", "fi": "Pohjois-Savo", "en": "North Savo"},
        "MK10 Södra Savolax": {"sv": "Södra Savolax", "fi": "Etelä-Savo", "en": "South Savo"},
        "MK09 Södra Karelen": {"sv": "Södra Karelen", "fi": "Etelä-Karjala", "en": "South Karelia"},
        "MK13 Mellersta Finland": {"sv": "Mellersta Finland", "fi": "Keski-Suomi", "en": "Central Finland"},
        "MK14 Södra Österbotten": {"sv": "Södra Österbotten", "fi": "Etelä-Pohjanmaa", "en": "South Ostrobothnia"},
        "MK15 Österbotten": {"sv": "Österbotten", "fi": "Pohjanmaa", "en": "Ostrobothnia"},
        "MK16 Mellersta Österbotten": {"sv": "Mellersta Österbotten", "fi": "Keski-Pohjanmaa", "en": "Central Ostrobothnia"},
        "MK06 Birkaland": {"sv": "Birkaland", "fi": "Pirkanmaa", "en": "Pirkanmaa"},
        "MK04 Satakunta": {"sv": "Satakunta", "fi": "Satakunta", "en": "Satakunta"},
        "MK07 Päijänne-Tavastland": {"sv": "Päijänne-Tavastland", "fi": "Päijät-Häme", "en": "Päijät-Häme"},
        "MK05 Egentliga Tavastland": {"sv": "Egentliga Tavastland", "fi": "Kanta-Häme", "en": "Kanta-Häme"},
        "MK08 Kymmenedalen": {"sv": "Kymmenedalen", "fi": "Kymenlaakso", "en": "Kymenlaakso"},
        "MK01 Nyland": {"sv": "Nyland", "fi": "Uusimaa", "en": "Uusimaa"},
        "MK02 Egentliga Finland": {"sv": "Egentliga Finland", "fi": "Varsinais-Suomi", "en": "Southwest Finland"},
        "MK21 Åland": {"sv": "Åland", "fi": "Ahvenanmaa", "en": "Åland"},
        "Hela landet": {"sv": "Hela landet", "fi": "Koko maa", "en": "The whole country"}
    },

    "languages": {
        "totalt": {"sv": "Totalt", "fi": "Yhteensä", "en": "Total"},
        "inhemska språk totalt": {"sv": "INHEMSKA SPRÅK TOTALT", "fi": "KOTIMAISET KIELET YHTEENSÄ", "en": "NATIONAL LANGUAGES, TOTAL"},
        "finska": {"sv": "Finska", "fi": "Suomi", "en": "Finnish"},
        "svenska": {"sv": "Svenska", "fi": "Ruotsi", "en": "Swedish"},
        "samiska": {"sv": "Samiska", "fi": "Saame", "en": "Sami"},
        "främmande språk totalt": {"sv": "FRÄMMANDE SPRÅK", "fi": "VIERASKIELISET", "en": "FOREIGN LANGUAGES"},
        "abchaziska": {"sv": "Abchaziska", "fi": "Abhaasi", "en": "Abkhaz"},
        "afar": {"sv": "Afar", "fi": "Afar", "en": "Afar"},
        "afrikaans": {"sv": "Afrikaans", "fi": "Afrikaans", "en": "Afrikaans"},
        "aymara": {"sv": "Aymara", "fi": "Aimara", "en": "Aymara"},
        "akan": {"sv": "Akan", "fi": "Akan", "en": "Akan"},
        "albanska": {"sv": "Albanska", "fi": "Albania", "en": "Albanian"},
        "amhariska": {"sv": "Amhariska", "fi": "Amhara", "en": "Amharic"},
        "arabiska": {"sv": "Arabiska", "fi": "Arabia", "en": "Arabic"},
        "aragonska": {"sv": "Aragonska", "fi": "Aragonia", "en": "Aragonese"},
        "armeniska": {"sv": "Armeniska", "fi": "Armenia", "en": "Armenian"},
        "assamesiska": {"sv": "Assamesiska", "fi": "Assami", "en": "Assamese"},
        "avariskt språk": {"sv": "Avariskt språk", "fi": "Avaari", "en": "Avaric"},
        "azerbajdzjanska": {"sv": "Azerbajdzjanska", "fi": "Azeri", "en": "Azerbaijani"},
        "bambara": {"sv": "Bambara", "fi": "Bambara", "en": "Bambara"},
        "baskiska": {"sv": "Baskiska", "fi": "Baski, euskara", "en": "Basque"},
        "basjkiriska": {"sv": "Basjkiriska", "fi": "Baskiiri", "en": "Bashkir"},
        "bengali": {"sv": "Bengali", "fi": "Bengali", "en": "Bengali, Bangla"},
        "bihari": {"sv": "Bihari", "fi": "Biharikieli", "en": "Bihari"},
        "bislama": {"sv": "Bislama", "fi": "Bislama", "en": "Bislama"},
        "bosniska": {"sv": "Bosniska", "fi": "Bosnia", "en": "Bosnian"},
        "bulgariska": {"sv": "Bulgariska", "fi": "Bulgaria", "en": "Bulgarian"},
        "burmesiska": {"sv": "Burmesiska", "fi": "Burma", "en": "Burmese"},
        "cree": {"sv": "Cree", "fi": "Cree", "en": "Cree"},
        "divehi": {"sv": "Divehi", "fi": "Divehi", "en": "Divehi, Dhivehi, Maldivian"},
        "dzongkha": {"sv": "Dzongkha", "fi": "Dzongkha, bhutani", "en": "Dzongkha"},
        "engelska": {"sv": "Engelska", "fi": "Englanti", "en": "English"},
        "spanska": {"sv": "Spanska", "fi": "Espanja", "en": "Spanish"},
        "esperanto": {"sv": "Esperanto", "fi": "Esperanto", "en": "Esperanto"},
        "sydndebele": {"sv": "Sydndebele", "fi": "Etelä-ndebele", "en": "Southern ndebele"},
        "sesotho": {"sv": "Sesotho", "fi": "Eteläsotho, sotho, sesotho", "en": "Southern Sotho"},
        "ewe": {"sv": "Ewe", "fi": "Ewe", "en": "Ewe"},
        "fijianska": {"sv": "Fijianska", "fi": "Fidzi", "en": "Fijian"},
        "fulani": {"sv": "Fulani", "fi": "Fulani, fulfulde", "en": "Fula, Fulah, Pulaar, Pular"},
        "färöiska": {"sv": "Färöiska", "fi": "Fääri", "en": "Faroese"},
        "galiciska": {"sv": "Galiciska", "fi": "Galicia", "en": "Galician"},
        "luganda": {"sv": "Luganda", "fi": "Ganda, luganda", "en": "Ganda"},
        "georgiska": {"sv": "Georgiska", "fi": "Georgia, gruusia", "en": "Georgian"},
        "grönländska": {"sv": "Grönländska", "fi": "Grönlanti, kalaallisut", "en": "Kalaallisut, Greenlandic"},
        "guarani": {"sv": "Guarani", "fi": "Guarani", "en": "Guaraní"},
        "gujarati": {"sv": "Gujarati", "fi": "Gudzarati, gujarati", "en": "Gujarati"},
        "hausa": {"sv": "Hausa", "fi": "Hausa", "en": "Hausa"},
        "hebreiska": {"sv": "Hebreiska", "fi": "Heprea, ivrit", "en": "Hebrew"},
        "herero": {"sv": "Herero", "fi": "Herero", "en": "Herero"},
        "hindi": {"sv": "Hindi", "fi": "Hindi", "en": "Hindi"},
        "nederländska": {"sv": "Nederländska", "fi": "Hollanti", "en": "Dutch"},
        "ido": {"sv": "Ido", "fi": "Ido", "en": "Ido"},
        "ibo, igbo": {"sv": "Ibo, igbo", "fi": "Igbo, ibo", "en": "Igbo"},
        "iriska": {"sv": "Iriska", "fi": "Iiri", "en": "Irish"},
        "indonesiska": {"sv": "Indonesiska", "fi": "Indonesia, bahasa indonésia", "en": "Indonesian"},
        "isländska": {"sv": "Isländska", "fi": "Islanti", "en": "Icelandic"},
        "italienska": {"sv": "Italienska", "fi": "Italia", "en": "Italian"},
        "javanesiska": {"sv": "Javanesiska", "fi": "Jaava", "en": "Javanese"},
        "japanska": {"sv": "Japanska", "fi": "Japani", "en": "Japanese"},
        "jiddisch": {"sv": "Jiddisch", "fi": "Jiddis", "en": "Yiddish"},
        "yoruba": {"sv": "Yoruba", "fi": "Joruba", "en": "Yoruba"},
        "kannada": {"sv": "Kannada", "fi": "Kannada", "en": "Kannada"},
        "kanuri": {"sv": "Kanuri", "fi": "Kanuri", "en": "Kanuri"},
        "kashmiri": {"sv": "Kashmiri", "fi": "Kasmiri", "en": "Kashmiri"},
        "katalanska": {"sv": "Katalanska", "fi": "Katalaani", "en": "Catalan"},
        "luba-katanga": {"sv": "Luba-katanga", "fi": "Katanganluba, luba", "en": "Luba-Katanga"},
        "kazakiska": {"sv": "Kazakiska", "fi": "Kazakki", "en": "Kazakh"},
        "kambodjanska": {"sv": "Kambodjanska", "fi": "Keskikhmer, khmer, kambodza", "en": "Khmer"},
        "quechua": {"sv": "Quechua", "fi": "Ketsua", "en": "Quechua"},
        "kinesiska": {"sv": "Kinesiska", "fi": "Kiina", "en": "Chinese"},
        "kikuyu": {"sv": "Kikuyu", "fi": "Kikuju", "en": "Kikuyu, Gikuyu"},
        "kirgiziska": {"sv": "Kirgiziska", "fi": "Kirgiisi", "en": "Kyrgyz"},
        "komi": {"sv": "Komi", "fi": "Komi", "en": "Komi"},
        "kikongo": {"sv": "Kikongo", "fi": "Kongo, kikongo", "en": "Kongo"},
        "koreanska": {"sv": "Koreanska", "fi": "Korea", "en": "Korean"},
        "grekiska": {"sv": "Grekiska", "fi": "Kreikka", "en": "Greek (modern)"},
        "kroatiska": {"sv": "Kroatiska", "fi": "Kroatia", "en": "Croatian"},
        "kwanyama": {"sv": "Kwanyama", "fi": "Kuanjama", "en": "Kwanyama, Kuanyama"},
        "kurdiska": {"sv": "Kurdiska", "fi": "Kurdi", "en": "Kurdish"},
        "kymriska": {"sv": "Kymriska", "fi": "Kymri, wales", "en": "Welsh"},
        "laotiska": {"sv": "Laotiska", "fi": "Lao", "en": "Lao"},
        "latin": {"sv": "Latin", "fi": "Latina", "en": "Latin"},
        "lettiska": {"sv": "Lettiska", "fi": "Latvia, lätti", "en": "Latvian"},
        "litauiska": {"sv": "Litauiska", "fi": "Liettua", "en": "Lithuanian"},
        "limburgiska": {"sv": "Limburgiska", "fi": "Limburgi", "en": "Limburgish, Limburgan, Limburger"},
        "lingala": {"sv": "Lingala", "fi": "Lingala", "en": "Lingala"},
        "letzebuergesch, luxemburgiska": {"sv": "Letzebuergesch, luxemburgiska", "fi": "Luxemburg, letzeburg", "en": "Luxembourgish, Letzeburgesch"},
        "frisiska": {"sv": "Frisiska", "fi": "Länsifriisi, friisi", "en": "Western Frisian"},
        "makedonska": {"sv": "Makedonska", "fi": "Makedonia", "en": "Macedonian"},
        "madagaskiska": {"sv": "Madagaskiska", "fi": "Malagasi, madagassi", "en": "Malagasy"},
        "malajiska": {"sv": "Malajiska", "fi": "Malaiji", "en": "Malay"},
        "malayalam": {"sv": "Malayalam", "fi": "Malajalam", "en": "Malayalam"},
        "maltesiska": {"sv": "Maltesiska", "fi": "Malta", "en": "Maltese"},
        "marathi": {"sv": "Marathi", "fi": "Marathi", "en": "Marathi"},
        "moldaviska": {"sv": "Moldaviska", "fi": "Moldova, moldavia", "en": "Moldavian"},
        "mongoliska": {"sv": "Mongoliska", "fi": "Mongoli", "en": "Mongolian"},
        "nauriska": {"sv": "Nauriska", "fi": "Nauru", "en": "Nauru"},
        "ndonga": {"sv": "Ndonga", "fi": "Ndonga, ambo", "en": "Ndonga"},
        "nepali": {"sv": "Nepali", "fi": "Nepali", "en": "Nepali"},
        "nyanja": {"sv": "Nyanja", "fi": "Njandza, tsitsewa, tsewa", "en": "Chichewa, Chewa, Nyanja"},
        "norska": {"sv": "Norska", "fi": "Norja", "en": "Norwegian"},
        "oriya": {"sv": "Oriya", "fi": "Orija", "en": "Oriya"},
        "afan oromo": {"sv": "Afan oromo", "fi": "Oromo, afan oromo", "en": "Oromo"},
        "ossetiska": {"sv": "Ossetiska", "fi": "Osseetti", "en": "Ossetian, Ossetic"},
        "pali": {"sv": "Pali", "fi": "Paali", "en": "Pali"},
        "punjabi": {"sv": "Punjabi", "fi": "Pandzabi", "en": "Panjabi, Punjabi"},
        "pashto": {"sv": "Pashto", "fi": "Pastu, afgaani", "en": "Pashto, Pushto"},
        "persiska": {"sv": "Persiska", "fi": "Persia, farsi", "en": "Persian, Farsi"},
        "ndebele": {"sv": "Ndebele", "fi": "Pohjoisndebele", "en": "Northern Ndebele"},
        "portugisiska": {"sv": "Portugisiska", "fi": "Portugali", "en": "Portuguese"},
        "polska": {"sv": "Polska", "fi": "Puola", "en": "Polish"},
        "franska": {"sv": "Franska", "fi": "Ranska", "en": "French"},
        "rätoromanska": {"sv": "Rätoromanska", "fi": "Retoromaani", "en": "Romansh"},
        "rumänska": {"sv": "Rumänska", "fi": "Romania", "en": "Romanian"},
        "kinyarwanda": {"sv": "Kinyarwanda", "fi": "Ruanda, kinjaruanda, njaruanda", "en": "Kinyarwanda"},
        "kirundi": {"sv": "Kirundi", "fi": "Rundi, kirundi", "en": "Kirundi"},
        "tyska": {"sv": "Tyska", "fi": "Saksa", "en": "German"},
        "samoanska": {"sv": "Samoanska", "fi": "Samoa", "en": "Samoan"},
        "sangho": {"sv": "Sangho", "fi": "Sango", "en": "Sango"},
        "serbiska": {"sv": "Serbiska", "fi": "Serbia", "en": "Serbian"},
        "serbokroatiska": {"sv": "Serbokroatiska", "fi": "Serbokroatia", "en": "Serbo-Croatian"},
        "sindhi": {"sv": "Sindhi", "fi": "Sindhi", "en": "Sindhi"},
        "singalesiska": {"sv": "Singalesiska", "fi": "Sinhala, singali", "en": "Sinhala, Sinhalese"},
        "gaeliska": {"sv": "Gaeliska", "fi": "Skotti, gaeli", "en": "Scottish Gaelic, Gaelic"},
        "slovakiska": {"sv": "Slovakiska", "fi": "Slovakki", "en": "Slovak"},
        "slovenska": {"sv": "Slovenska", "fi": "Sloveeni", "en": "Slovene"},
        "somaliska": {"sv": "Somaliska", "fi": "Somali", "en": "Somali"},
        "shona": {"sv": "Shona", "fi": "Sona, shona", "en": "Shona"},
        "sundanesiska": {"sv": "Sundanesiska", "fi": "Sunda", "en": "Sundanese"},
        "swahili": {"sv": "Swahili", "fi": "Swahili, suahili", "en": "Swahili"},
        "siswati": {"sv": "Siswati", "fi": "Swazi, siswati", "en": "Swati"},
        "tadzjikiska": {"sv": "Tadzjikiska", "fi": "Tadzikki", "en": "Tajik"},
        "tagalog": {"sv": "Tagalog", "fi": "Tagalog, pilipino", "en": "Tagalog"},
        "tahitiska": {"sv": "Tahitiska", "fi": "Tahiti", "en": "Tahitian"},
        "tamil": {"sv": "Tamil", "fi": "Tamili", "en": "Tamil"},
        "danska": {"sv": "Danska", "fi": "Tanska", "en": "Danish"},
        "tatariska": {"sv": "Tatariska", "fi": "Tataari", "en": "Tatar"},
        "telugu": {"sv": "Telugu", "fi": "Telugu", "en": "Telugu"},
        "thailändska": {"sv": "Thailändska", "fi": "Thai", "en": "Thai"},
        "tigrinja": {"sv": "Tigrinja", "fi": "Tigrinja", "en": "Tigrinya"},
        "tigrinska": {"sv": "Tigrinja", "fi": "Tigrinja", "en": "Tigrinya"},
        "tibetanska": {"sv": "Tibetanska", "fi": "Tiibet", "en": "Tibetan Standard, Tibetan, Central"},
        "tonga": {"sv": "Tonga", "fi": "Tonga", "en": "Tonga, Tonga Islands"},
        "chamorro": {"sv": "Chamorro", "fi": "Tsamorro", "en": "Chamorro"},
        "tjeckiska": {"sv": "Tjeckiska", "fi": "Tsekki", "en": "Czech"},
        "tjetjenska": {"sv": "Tjetjenska", "fi": "Tsetseeni", "en": "Chechen"},
        "tsonga": {"sv": "Tsonga", "fi": "Tsonga", "en": "Tsonga"},
        "tjuvasjiska": {"sv": "Tjuvasjiska", "fi": "Tsuvassi", "en": "Chuvash"},
        "tswana": {"sv": "Tswana", "fi": "Tswana, setswana", "en": "Tswana"},
        "turkiska": {"sv": "Turkiska", "fi": "Turkki", "en": "Turkish"},
        "turkmenska": {"sv": "Turkmenska", "fi": "Turkmeeni", "en": "Turkmen"},
        "twi": {"sv": "Twi", "fi": "Twi", "en": "Twi"},
        "uiguriska": {"sv": "Uiguriska", "fi": "Uiguuri", "en": "Uyghur"},
        "ukrainska": {"sv": "Ukrainska", "fi": "Ukraina", "en": "Ukrainian"},
        "ungerska": {"sv": "Ungerska", "fi": "Unkari", "en": "Hungarian"},
        "urdu": {"sv": "Urdu", "fi": "Urdu", "en": "Urdu"},
        "uzbekiska": {"sv": "Uzbekiska", "fi": "Uzbekki", "en": "Uzbek"},
        "vitryska": {"sv": "Vitryska", "fi": "Valkovenäjä", "en": "Belarusian"},
        "venda": {"sv": "Venda", "fi": "Venda", "en": "Venda"},
        "ryska": {"sv": "Ryska", "fi": "Venäjä", "en": "Russian"},
        "vietnamesiska": {"sv": "Vietnamesiska", "fi": "Vietnam", "en": "Vietnamese"},
        "estniska": {"sv": "Estniska", "fi": "Viro, eesti", "en": "Estonian"},
        "wolof": {"sv": "Wolof", "fi": "Wolof", "en": "Wolof"},
        "xhosa": {"sv": "Xhosa", "fi": "Xhosa", "en": "Xhosa"},
        "zhuang": {"sv": "Zhuang", "fi": "Zhuang, tsuang", "en": "Zhuang, Chuang"},
        "zulu": {"sv": "Zulu", "fi": "Zulu", "en": "Zulu"},
        "annat språk": {"sv": "Annat språk", "fi": "Muu kieli", "en": "Other language"},
        "okänt": {"sv": "Okänt", "fi": "Tuntematon", "en": "Unknown"}
        }
}
